import React from 'react';

function Form({ onSubmit, children }) {
  return (
    <form className="mx-auto flex h-full flex-col 2xl:my-20" onSubmit={onSubmit}>
      <div className="my-12 mx-auto w-full max-w-md">{children}</div>
    </form>
  );
}

export default Form;
