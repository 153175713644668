import React, { Component } from 'react';

class FilterTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  setOpening = (e) => {
    this.setState({ open: !this.state.open });
  };
  render() {
    const { open } = this.state;
    return (
      <div className="mb-6 mt-12 max-w-md">
        <div className="px-6 xl:px-0">
          <button
            onClick={this.setOpening}
            className="mb-6 block flex w-full  items-center justify-between border-b border-black pb-2 text-21px leading-24px xl:text-28px xl:leading-36px "
          >
            <div>{this.props.title}</div>
            <div className={`${open ? 'rotate-180 transform' : ''}`}>
              <svg width="1em" height="1em" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6l-6 6l1.41 1.41z"></path>
              </svg>
            </div>
          </button>

          <div className={`text-21px leading-24px ${open ? 'block' : 'hidden'}`}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default FilterTab;
