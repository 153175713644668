import React from 'react';
import { FicheHighlight } from '..';
function FicheHit({ hit, handleClick }) {
  const showFiche = () => {
    handleClick(hit.encodage_nid);
  };

  return (
    <button onClick={showFiche} className="block w-full px-4 py-2 text-left font-semibold hover:bg-bright-orange">
      <div className="aa-ItemContent">
        <div className="aa-ItemTitle">
          <FicheHighlight hit={hit} attribute="title" />
        </div>
      </div>
    </button>
  );
}

export default FicheHit;
