import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pager, AnthologiePreviewFiche } from '..';
import List from './List';

export default class AnthologiesList extends Component {
  render() {
    let { anthologies, withPager } = this.props;
    const number_per_pages = 12;
    const filteredAnthologies = anthologies.filter((antho) => antho.creator !== null);
    return (
      filteredAnthologies &&
      (withPager ? (
        <Pager number_per_pages={number_per_pages}>
          {filteredAnthologies.map((lib, i) => (
            <AnthologiePreviewFiche anthologie={lib} key={`antho-${i}`} />
          ))}
        </Pager>
      ) : (
        <List>
          {filteredAnthologies.map((lib, i) => (
            <AnthologiePreviewFiche anthologie={lib} key={`antho-${i}`} />
          ))}
        </List>
      ))
    );
  }
}
AnthologiesList.propTypes = { anthologies: PropTypes.array };
