import React, { Component } from 'react';

import { FacebookShareButton, TwitterShareButton } from 'react-share';

export default class ShareButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }
  setCopied = () => {
    this.setState({ copied: !this.state.copied });
  };
  addToClipBoard = (e) => {
    navigator.clipboard.writeText(window.location.href);
    this.setCopied();
    setTimeout(this.setCopied, 1500);
  };

  render() {
    const { show, setShareHidden } = this.props;
    const { copied } = this.state;
    const url = window.location.href;

    return (
      <>
        <div
          className={`fixed inset-0 h-full w-full ${show ? 'block' : 'hidden'} cursor-pointer`}
          onClick={setShareHidden}
        ></div>

        <div
          className={`absolute right-0 z-50 flex items-center justify-center ${
            show ? 'block' : 'hidden'
          } cursor-pointer`}
        >
          <div className="w-[200px] cursor-default rounded border border-light-gray bg-white py-2 text-14px leading-20px shadow-xl md:text-18px md:leading-24px">
            <div className="flex flex-col">
              <div className="share-link relative flex">
                {copied && <div className="hover-text">URL ajoutée au presse-papier</div>}

                <button onClick={this.addToClipBoard}>Lien hypertexte</button>
              </div>
              <FacebookShareButton url={url}>
                <div className="share-link block">Facebook</div>
              </FacebookShareButton>
              <TwitterShareButton url={url}>
                <div className="share-link">Twitter</div>
              </TwitterShareButton>
            </div>
          </div>
        </div>
      </>
    );
  }
}
