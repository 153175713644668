import axios from 'axios';

const api_url = process.env.REACT_APP_API_URL;

//Sites
const getSiteByName = (site_name) => axios.get(`${api_url}/site/name/${site_name}`).then((response) => response.data);

//Fiches
const getFiche = (fiche_id) => axios.get(`${api_url}/fiche/${fiche_id}`).then((response) => response.data);

const getFicheByNid = (fiche_nid) => axios.get(`${api_url}/fiche/nid/${fiche_nid}`).then((response) => response.data);

//Anthologies
const getAllFeaturedAnthologies = () => axios.get(`${api_url}/anthologies/featured`).then((response) => response.data);

const getAllPublicAnthologies = () =>
  axios.get(`${api_url}/anthologies/public/notfeatured`).then((response) => response.data);

const getAnthologiesWithFiche = (ficheId, accessToken = null) =>
  axios
    .put(`${api_url}/anthologies/withFiche`, { ficheId }, { headers: { 'x-access-token': accessToken } })
    .then((response) => response.data);

const createAnthologie = (args) => axios.post(`${api_url}/anthologies`, args).then((response) => response.data);

const getAnthologie = (anthologie_id) =>
  axios.get(`${api_url}/anthologie/${anthologie_id}`).then((response) => response.data);

const updateAnthologie = (args) =>
  axios.put(`${api_url}/anthologie/${args['_id']}`, args).then((response) => response.data);

const deleteAnthologie = (anthologie_id) =>
  axios.delete(`${api_url}/anthologie/${anthologie_id}`).then((response) => 'Deleted');

//User
const loginUser = (args) =>
  axios
    .post(`${api_url}/user/signin`, args)
    .then((response) => response.data)
    .catch((error) => errorCatcher(error));

const registerUser = (args) =>
  axios
    .post(`${api_url}/user/signup`, args)
    .then((response) => response.data)
    .catch((error) => errorCatcher(error));

const testUserToken = (access_token) =>
  axios.get(`${api_url}/user`, { headers: { 'x-access-token': access_token } }).then((response) => response.data);

const updateUser = (user, access_token) =>
  axios
    .put(`${api_url}/user/${user._id}`, user, { headers: { 'x-access-token': access_token } })
    .then((response) => response.data);

const getProfil = (user_id) => axios.get(`${api_url}/profil/${user_id}`).then((response) => response.data);

const getUserAnthologies = (user_id) =>
  axios.get(`${api_url}/anthologies/user/${user_id}`).then((response) => response.data);

const getUserPrivateAnthologies = (user_id) =>
  axios.get(`${api_url}/anthologies/user/${user_id}/private`).then((response) => response.data);

const getUserPublicAnthologies = (user_id) =>
  axios.get(`${api_url}/anthologies/user/${user_id}/public`).then((response) => response.data);

const sendResetPasswordEmail = (args) =>
  axios
    .post(`${api_url}/send-reset-token`, args)
    .then((response) => response.data)
    .catch((error) => errorCatcher(error));

const resetPasswordWithUser = (args, access_token) =>
  axios
    .post(`${api_url}/user/reset-password`, args, { headers: { 'x-access-token': access_token } })
    .then((response) => response.data)
    .catch((error) => errorCatcher(error));

const resetPasswordWithToken = (args) =>
  axios
    .put(`${api_url}/user/reset-password`, args)
    .then((response) => response.data)
    .catch((error) => errorCatcher(error));

const errorCatcher = (error) => {
  if (error.response) {
    return error.response.data;
  } else if (error.request) {
    return error.request;
  } else {
    return error.message;
  }
};
export {
  getSiteByName,
  getFiche,
  getFicheByNid,
  getAllFeaturedAnthologies,
  getAllPublicAnthologies,
  getAnthologiesWithFiche,
  createAnthologie,
  getAnthologie,
  updateAnthologie,
  deleteAnthologie,
  getUserAnthologies,
  getUserPrivateAnthologies,
  getUserPublicAnthologies,
  updateUser,
  getProfil,
  sendResetPasswordEmail,
  resetPasswordWithUser,
  resetPasswordWithToken,
  loginUser,
  registerUser,
  testUserToken,
};
