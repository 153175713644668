import React, { useState } from 'react';
import { resetPasswordWithUser } from '../../api';
import { InputPassword } from '..';
import { useAuthContext } from '../../common/context/authContext';
import { toast } from 'react-toastify';
import { validatePassword } from '../../common/helpers/PasswordHelper';
import { useNavigate } from 'react-router-dom';

function PasswordForm({ disabled, setSelectedIndex }) {
  const { user } = useAuthContext();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const args = {
      _id: user._id,
      currentPassword,
      newPassword,
      newPasswordConfirm,
    };
    const errors = validatePassword(newPassword);

    if (errors.length > 0) {
      setErrorMessage(errors.join('\n'));
    } else {
      setErrorMessage(null);
      const token = localStorage.getItem('accessToken');
      resetPasswordWithUser(args, token).then((res) => {
        if (res.error) {
          setErrorMessage(res.error);
        } else if (res.success) {
          toast(res.success);
          setSelectedIndex(0);
        } else {
          navigate('/account');
          toast.error('Veuillez vous reconnecter');
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3 className="mb-6 md:mb-8">Changer le mot de passe</h3>
      <div className="mb-6">
        <InputPassword
          title="Ancien mot de passe"
          name="currentPassword"
          value={currentPassword}
          onChange={setCurrentPassword}
        />
      </div>

      <div className="mb-6 md:mb-8">
        <InputPassword title="Nouveau mot de passe" name="newPassword" value={newPassword} onChange={setNewPassword} />
        <p className="mt-2 text-16px leading-20px opacity-50 md:text-20px md:leading-24px">
          Le nouveau mot de passe doit contenir au moins 8 caractères, une majuscule et au moins un chiffre.
        </p>
      </div>

      <div className="mb-6 md:mb-8">
        <InputPassword
          title="Entrer de nouveau le mot de passe"
          name="newPasswordConfirm"
          value={newPasswordConfirm}
          onChange={setNewPasswordConfirm}
        />
      </div>

      <input
        className="link-black mt-4 cursor-pointer border border-black py-3 px-6 py-4 text-center text-14px leading-20px hover:bg-light-gray md:text-18px md:leading-24px"
        type="submit"
        value="Sauvegarder les modifications"
        disabled={disabled}
      />
      {errorMessage && (
        <div className="mt-3 font-bold text-red-500" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
      )}
    </form>
  );
}

export default PasswordForm;
