import React, { Component } from 'react';
import { Grid } from '../..';

export default class Pager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_page: 0,
      number_of_pages: this.props.number_of_pages,
    };
  }
  setPage = (page) => {
    this.setState({ current_page: page });
  };

  render() {
    const { current_page } = this.state;
    const { number_per_pages, content_class_name, children } = this.props;
    const arrayChildren = React.Children.toArray(children);

    const firstItem = current_page * number_per_pages;
    const lastItem = firstItem + number_per_pages;
    const number_of_pages = Math.ceil(arrayChildren.length / number_per_pages);
    return (
      <div>
        <div className={content_class_name}>
          <Grid>{[...arrayChildren.slice(firstItem, lastItem)].map((child, i) => child)}</Grid>
        </div>
        <div className="mt-8 flex items-center justify-center gap-8 text-20px leading-24px md:mt-20 lg:mt-24">
          {number_of_pages !== 0 && current_page > 0 ? (
            <button className="page-link-item" onClick={() => this.setPage(current_page - 1)}>
              Précédent
            </button>
          ) : (
            <svg className="cursor-not-allowed opacity-30" width="1em" height="1em" viewBox="0 0 24 24">
              <path fill="currentColor" d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20v-2z"></path>
            </svg>
          )}

          {[...Array(number_of_pages)].map((e, i) => (
            <button
              className={`flex h-12 w-12 items-center justify-center ${
                current_page === i ? 'bg-bright-orange' : 'bg-white'
              } font-semibold`}
              onClick={() => this.setPage(i)}
              key={`page-${i}`}
            >
              {i + 1}
            </button>
          ))}

          {number_of_pages !== 0 && current_page < number_of_pages - 1 ? (
            <button onClick={() => this.setPage(current_page + 1)}>Suivant</button>
          ) : (
            <svg className="cursor-not-allowed opacity-30" width="1em" height="1em" viewBox="0 0 24 24">
              <path fill="currentColor" d="m12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8l-8-8z"></path>
            </svg>
          )}
        </div>
      </div>
    );
  }
}
