import React from 'react';
import Helmet from 'react-helmet';
import { LoginForm, MyProfile } from '../components';
import { useAuthContext } from '../common/context/authContext';

function AccountPage() {
  const { isLoggedIn } = useAuthContext();

  const renderLogoffAccountPage = () => {
    return (
      <section className="account-form">
        <LoginForm />
      </section>
    );
  };
  const renderLoginAccountPage = () => {
    return (
      <>
        <MyProfile />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Mon compte - Encodage</title>
      </Helmet>
      {isLoggedIn ? renderLoginAccountPage() : renderLogoffAccountPage()}
    </>
  );
}

export default AccountPage;
