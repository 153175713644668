import React, { Component } from 'react';

export default class Error404 extends Component {
  render() {
    return (
      <main className="page-error">
        <header className="page-header">
          <h1>Erreur 404</h1>
        </header>
        <div className="page-content">
          <p>Nothing to see here...</p>
        </div>
      </main>
    );
  }
}
