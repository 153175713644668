import React, { useState } from 'react';
import withRouter from '../HOC/withRouter';
import { InputPassword } from '../components';
import { resetPasswordWithToken } from '../api';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { validatePassword } from '../common/helpers/PasswordHelper';

function ResetPasswordPage({ navigate }) {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  if (!token) {
    navigate('/');
  }

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const args = {
      newPassword,
      newPasswordConfirm,
      token: token,
    };
    const errors = validatePassword(newPassword);
    if (errors.length > 0) {
      setErrorMessage(errors.join('\n'));
    } else {
      setErrorMessage(null);
      resetPasswordWithToken(args).then((res) => {
        if (res.error) {
          toast.error(res.error);
        } else if (res.success) {
          toast(res.success);
          navigate('/account');
        }
      });
    }
  };

  return (
    <section className="flex items-center justify-center">
      <form className="max-w-md" onSubmit={handleSubmit}>
        {errorMessage && <div className="text-red-500" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>}

        <h3 className="mb-6 md:mb-8">Changer le mot de passe</h3>

        <div className="mb-6 md:mb-8">
          <InputPassword
            title="Nouveau mot de passe"
            name="newPassword"
            value={newPassword}
            onChange={setNewPassword}
          />
          <p className="mt-2 text-16px leading-20px opacity-50 md:text-20px md:leading-24px">
            Le nouveau mot de passe doit contenir au moins 8 caractères, une majuscule et au moins un chiffre.
          </p>
        </div>

        <div className="mb-6 md:mb-8">
          <InputPassword
            title="Confirmer le mot de passe"
            name="newPasswordConfirm"
            value={newPasswordConfirm}
            onChange={setNewPasswordConfirm}
          />
        </div>

        <input
          className="link-black mt-4 cursor-pointer border border-black py-3 px-6 py-4 text-center text-14px leading-20px hover:bg-light-gray md:text-18px md:leading-24px"
          type="submit"
          value="Envoyer"
        />
      </form>
    </section>
  );
}

export default withRouter(ResetPasswordPage);
