import React from 'react';

function IconText({ children, title }) {
  return (
    <div className="md:leading-22px flex items-center p-2 text-14px leading-20px hover:bg-light-gray md:text-16px">
      {children}
      <div>{title}</div>
    </div>
  );
}

export default IconText;
