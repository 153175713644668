import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AnthologieEditor, AnthologiePreviewList, Pager } from '..';
import List from './List';
import { deleteAnthologie } from '../../api';

export default class AnthologiesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_mode: false,
      edited_id: false,
    };
  }
  closeEditor = () => {
    this.setState({ edited_id: null, edit_mode: false });
    this.props.reloadUserAnthologies();
  };
  openEditor = (edited_id) => {
    this.setState({ edited_id, edit_mode: true });
  };
  deleteAnthologie = (deleted_id) => {
    deleteAnthologie(deleted_id).then((res) => {
      this.props.reloadUserAnthologies();
    });
  };
  render() {
    let { anthologies, withPager } = this.props;
    anthologies = anthologies.filter((antho) => antho.creator !== null);

    const { edit_mode, edited_id } = this.state;
    const number_per_pages = 12;
    return (
      anthologies && (
        <>
          {withPager ? (
            <Pager number_per_pages={number_per_pages}>
              {anthologies.map((lib, i) => (
                <AnthologiePreviewList
                  anthologie={lib}
                  key={`antho-${i}`}
                  openEditor={this.openEditor}
                  deleteAnthologie={this.deleteAnthologie}
                />
              ))}
            </Pager>
          ) : (
            <List>
              {anthologies.map((lib, i) => (
                <AnthologiePreviewList
                  anthologie={lib}
                  key={`antho-${i}`}
                  openEditor={this.openEditor}
                  deleteAnthologie={this.deleteAnthologie}
                />
              ))}
            </List>
          )}
          <AnthologieEditor show={edit_mode} id={edited_id} closeEditor={this.closeEditor} />
        </>
      )
    );
  }
}
AnthologiesList.propTypes = { anthologies: PropTypes.array };
