import React, { Component } from 'react';
import withRouter from '../../HOC/withRouter';

class AnthologiePreviewList extends Component {
  handleClick = (e) => {
    // e.preventDefault()
    this.props.navigate(`/anthologie/${this.props.anthologie._id}`);
  };
  handleEditor = (e) => {
    e.stopPropagation();
    this.props.openEditor(this.props.anthologie._id);
  };
  handleDelete = (e) => {
    e.stopPropagation();
    if (window.confirm('Voulez vous réellement supprimer cette anthologie?')) {
      this.props.deleteAnthologie(this.props.anthologie._id);
      if (this.props.closeEditor) this.props.closeEditor();
    }
  };
  render() {
    const { anthologie } = this.props;
    return (
      <div className="link-bright-orange cursor-pointer bg-light-gray" id={anthologie._id} onClick={this.handleClick}>
        <div className="p-8">
          {anthologie.is_featured && (
            <div className="mb-4 flex items-center text-14px leading-20px md:text-18px md:leading-24px">
              <div className="mr-3 flex flex-col items-center">
                <hr className="border-0.5 w-6 border-black" />
              </div>
              <div>Vedette</div>
            </div>
          )}
          <div className="gap-8 xl:flex xl:items-center xl:justify-between">
            <div className="mb-8 xl:mb-0">
              <div className="mb-4 break-words text-24px leading-30px line-clamp-6 sm:line-clamp-5 md:text-28px md:leading-36px lg:line-clamp-3 xl:text-36px xl:leading-48px xl:line-clamp-4">
                {anthologie.name}
              </div>
            </div>
            <div className="flex items-center gap-4">
              <button
                className="flex items-center gap-2 border border-black py-3 px-6 text-14px leading-20px hover:bg-light-gray"
                onClick={this.handleEditor}
              >
                <svg width="1em" height="1em" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM21.41 6.34l-3.75-3.75l-2.53 2.54l3.75 3.75l2.53-2.54z"
                  ></path>
                </svg>
                <span>Modifier</span>
              </button>
              <button
                className="flex items-center gap-2 border border-black py-3 px-6 text-14px leading-20px hover:bg-light-gray"
                onClick={this.handleDelete}
              >
                <svg width="1em" height="1em" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM21.41 6.34l-3.75-3.75l-2.53 2.54l3.75 3.75l2.53-2.54z"
                  ></path>
                </svg>
                <span>Supprimer</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AnthologiePreviewList);
