import React from 'react';

function Logo({ url, svg, name }) {
  return (
    <a rel="noreferrer" href={url} target="_blank" el="noopener noreferrer">
      <div className="relative flex h-36 flex-col items-center justify-center bg-lightest-gray px-6">
        <img src={svg} alt={name} />
        <div className="absolute h-full w-full hover:border-b hover:border-bright-orange"></div>
      </div>
    </a>
  );
}

export default Logo;
