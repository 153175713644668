import React, { createRef, useEffect, useState } from 'react';
import { getAnthologiesWithFiche, getFicheByNid, getSiteByName } from '../api';
import withRouter from '../HOC/withRouter';

import { AnthologiesListFiche, AnthologieSelector, IconText, ShareButtons } from '../components';

import { PDFExport } from '@progress/kendo-react-pdf';
import { useAuthContext } from '../common/context/authContext';
import Helmet from 'react-helmet';
import { slugify } from '../common/helpers/Slugify';

function FichePage({ match, navigate }) {
  const { isLoggedIn, accessToken } = useAuthContext();
  const [isExporting, setIsExporting] = useState(false);

  const { ficheNid, anthologyId } = match.params;
  if (!ficheNid) {
    window.location = '/';
  }
  const printRef = createRef(null);

  const [nid, setNid] = useState(ficheNid);
  const [fiche, setFiche] = useState(null);

  const [ficheAnthologies, setFicheAnthologies] = useState(null);
  const [relatedSiteUrl, setRelatedSiteUrl] = useState('');
  const [showSelector, setShowSelector] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [show_share, setShow_share] = useState(false);

  useEffect(() => {
    setNid(ficheNid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    queryFiche(nid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nid]);

  const queryFiche = (nid) => {
    getFicheByNid(nid).then((fiche) => {
      // if (!fiche) window.location = "/"
      if (fiche) {
        setFiche(fiche);
      } else {
        setInvalid(true);
      }
    });
  };

  useEffect(() => {
    if (fiche) {
      async function fetchData() {
        await get_related_site();
        await queryFicheAnthologies();
      }

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiche]);

  const queryFicheAnthologies = async () => {
    if (fiche) {
      getAnthologiesWithFiche(fiche._id, accessToken).then((fiche_anthologies) => {
        setFicheAnthologies(fiche_anthologies);
      });
    }
  };

  const get_related_site = async () => {
    await getSiteByName(fiche.source).then(async (site) => {
      setRelatedSiteUrl(site.url_frontend);
    });
  };

  const goBack = () => {
    if (anthologyId) {
      navigate(`/anthologie/${anthologyId}`);
    } else {
      navigate('/outils-recherche');
    }
  };

  const saveFiche = () => {
    setShowSelector(true);
  };

  const closeSelector = () => {
    setShowSelector(false);
    queryFicheAnthologies();
  };

  const setShareHidden = () => {
    setShow_share(false);
  };

  const setShareShow = () => {
    setShow_share(true);
  };

  const exportPDF = () => {
    setIsExporting(true);
  };

  useEffect(() => {
    if (printRef.current && isExporting) {
      printRef.current.save();
      setIsExporting(false);
    }
  }, [isExporting, printRef]);

  if (fiche) {
    const { _id, title, path, source, date_publication, authors, summary } = fiche;
    return (
      <>
        <Helmet>
          <title>Fiche - Encodage</title>
        </Helmet>
        <div className="mt-6 mb-20">
          <div className="mx-auto max-w-3xl px-4 md:px-0 lg:flex lg:max-w-none lg:justify-between">
            <div className="mb-6 lg:w-2/6">
              <button onClick={goBack}>
                <IconText title={anthologyId ? "Retour à l'anthologie" : "Retour à l'outil de recherche"}>
                  <svg className="mr-2" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20v-2z"></path>
                  </svg>
                </IconText>
              </button>
            </div>

            <div className="mx-auto max-w-3xl lg:w-4/6" id="fiche">
              <PDFExport
                ref={printRef}
                paperSize="Legal"
                margin={{
                  top: '0.79in',
                  left: '0.59in',
                  right: '0.59in',
                  bottom: '0.79in',
                }}
                scale={0.6}
                fileName={slugify(title)}
              >
                <div className="mb-6 flex flex-col md:mb-12 md:flex-row md:items-center md:justify-between">
                  <div className="order-2 flex items-center text-18px leading-24px md:order-1">
                    <hr className="border-0.5 mr-4 w-6 border-black" />
                    <div className="text-21px leading-24px md:text-24px md:leading-30px">Fiche</div>
                  </div>
                  {!isExporting && (
                    <div className="right-12 top-0 order-1 mb-12 flex w-fit md:order-2 md:mb-0">
                      <button onClick={setShareShow}>
                        <IconText title="Partager">
                          <svg className="mr-2" width="1em" height="1em" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81c1.66 0 3-1.34 3-3s-1.34-3-3-3s-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65c0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"
                            ></path>
                          </svg>
                        </IconText>
                      </button>
                      <ShareButtons
                        show={show_share}
                        setShareHidden={setShareHidden}
                        name={title}
                        exportPDF={exportPDF}
                        selector="#fiche"
                      />

                      <button onClick={exportPDF}>
                        <IconText title="Exporter en .pdf">
                          <svg className="mr-2" width="1em" height="1em" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m-9.5 8.5c0 .8-.7 1.5-1.5 1.5H7v2H5.5V9H8c.8 0 1.5.7 1.5 1.5v1m5 2c0 .8-.7 1.5-1.5 1.5h-2.5V9H13c.8 0 1.5.7 1.5 1.5v3m4-3H17v1h1.5V13H17v2h-1.5V9h3v1.5m-6.5 0h1v3h-1v-3m-5 0h1v1H7v-1Z"
                            ></path>
                          </svg>
                        </IconText>
                      </button>
                      {isLoggedIn && (
                        <button onClick={saveFiche}>
                          <IconText title="Sauvegarder">
                            <svg className="mr-2" width="1em" height="1em" viewBox="0 0 24 24">
                              <path fill="currentColor" d="M19 3H5v18l7-3l7 3V3zm-2 15l-5-2.18L7 18V5h10v13z"></path>
                            </svg>
                          </IconText>
                        </button>
                      )}
                    </div>
                  )}
                </div>
                <h1 className="title mb-6 md:mb-8 lg:mb-12" dangerouslySetInnerHTML={{ __html: title }}></h1>
                <div className="mb-4 flex items-start text-14px leading-20px md:text-21px md:leading-24px">
                  <svg className="mr-3 mt-0.5 flex-none opacity-40" width="1em" height="1em" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12 5.9a2.1 2.1 0 1 1 0 4.2a2.1 2.1 0 0 1 0-4.2m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4s4-1.79 4-4s-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
                    ></path>
                  </svg>
                  <div>
                    {authors.map((author, i) => (
                      <span
                        key={`auteur-${i}`}
                        dangerouslySetInnerHTML={{
                          __html: `${author.firstname} ${author.lastname}${
                            fiche.authors.length > 1 && i < fiche.authors.length - 1 ? ', ' : ''
                          }`,
                        }}
                      ></span>
                    ))}
                  </div>
                </div>
                <div className="mb-4 flex items-start text-14px leading-20px md:text-21px md:leading-24px">
                  <svg className="mr-3 mt-1 flex-none opacity-40" width="1em" height="1em" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                    ></path>
                  </svg>
                  <div>{source}</div>
                </div>
                <div className="mb-6 flex items-start text-14px leading-20px md:mb-14 md:text-21px md:leading-24px">
                  <svg className="mr-3 flex-none opacity-40" width="1em" height="1em" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 18H5c-.55 0-1-.45-1-1V8h16v12c0 .55-.45 1-1 1z"
                    ></path>
                  </svg>

                  <div>
                    {new Date(date_publication).toLocaleDateString('fr-CA', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </div>
                </div>
                <div className="excerpt mb-10 max-w-xl text-16px leading-20px md:text-20px md:leading-24px lg:max-w-2xl">
                  <div dangerouslySetInnerHTML={{ __html: summary }}></div>
                </div>
                <a href={relatedSiteUrl + path} rel="noreferrer" target={'_blank'}>
                  <button className="flex items-center gap-2 border border-black p-4 text-14px leading-20px hover:bg-light-gray md:text-18px md:leading-24px">
                    <svg width="1em" height="1em" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M19 19H5V5h7V3H3v18h18v-9h-2v7zM14 3v2h3.59l-9.83 9.83l1.41 1.41L19 6.41V10h2V3h-7z"
                      ></path>
                    </svg>
                    <div>Voir la source</div>
                  </button>
                </a>
                {ficheAnthologies && ficheAnthologies.length > 0 && (
                  <>
                    <hr className="mt-10 mb-16" />
                    <div className="mb-10 text-24px leading-30px md:text-28px md:leading-36px">
                      Apparaît dans les anthologies&nbsp;:
                    </div>
                    <AnthologiesListFiche anthologies={ficheAnthologies} />
                  </>
                )}
              </PDFExport>
            </div>
          </div>
        </div>
        <AnthologieSelector fiche_id={_id} show={showSelector} closeSelector={closeSelector} />
      </>
    );
  } else if (invalid) {
    return 'Fiche non trouvée';
  } else {
    return 'Chargement...';
  }
}

export default withRouter(FichePage);
