import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import withRouter from '../../../HOC/withRouter';
import { useAuthContext } from '../../../common/context/authContext';

function MobileMenu() {
  const { isLoggedIn } = useAuthContext();

  const [isOpen, setIsOpen] = useState(false);

  const setModalOpen = () => {
    setIsOpen(!isOpen);
  };

  const dialogClose = () => {
    return null;
  };

  return (
    <>
      {isOpen ? (
        <button className="relative z-30" type="button" onClick={setModalOpen}>
          <svg width="2rem" height="2rem" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41z"
            ></path>
          </svg>
        </button>
      ) : (
        <button className="relative z-30" type="button" onClick={setModalOpen}>
          <svg width="2rem" height="2rem" viewBox="0 0 24 24">
            <path fill="currentColor" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
          </svg>
        </button>
      )}

      <Dialog open={isOpen} onClose={dialogClose}>
        <div className="fixed inset-0 bg-white" />

        <Dialog.Panel className="fixed inset-0 z-20 flex flex-col gap-20 bg-white px-12 pt-32 text-21px leading-24px">
          <Link className="outline-none" to="/" onClick={setModalOpen}>
            Recherche
          </Link>
          {/* )} */}
          <Link className="outline-none" to="/anthologies" onClick={setModalOpen}>
            Anthologies
          </Link>
          {isLoggedIn ? (
            <Link className="outline-none" to="/account" onClick={setModalOpen}>
              Compte
            </Link>
          ) : (
            <Link className="outline-none" to="/account" onClick={setModalOpen}>
              Connexion
            </Link>
          )}
          <Link className="outline-none" to="/a-propos" onClick={setModalOpen}>
            À propos
          </Link>
          <Link className="outline-none" to="/credits" onClick={setModalOpen}>
            Crédits
          </Link>
        </Dialog.Panel>
      </Dialog>
    </>
  );
}

export default withRouter(MobileMenu);
