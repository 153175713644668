import React from 'react';

function MetaTags({ url, title }) {
  return (
    <>
      <title>{this.props.title}</title>

      {/* Google / Search Engine Tags */}
      <meta itemprop="name" content={url} />
      <meta itemprop="image" content="./img/encodage-4-3.png" />

      {/* Facebook Meta Tags */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content="./img/encodage-4-3.png" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={url} />
      <meta name="twitter:image" content="./img/encodage-4-3.png" />
    </>
  );
}

export default MetaTags;
