const validatePassword = (password) => {
  let errors = [];
  if (password.length < 8) {
    errors.push('<p>- 8 caractères</p>');
  }
  if (password.search(/[a-z]/i) < 0) {
    errors.push('<p>- une lettre</p>');
  }
  if (password.search(/[A-Z]/) < 0) {
    errors.push('<p>- une majuscule</p>');
  }
  if (password.search(/[0-9]/) < 0) {
    errors.push('<p>- un chiffre</p>');
  }
  if (errors.length > 0) {
    errors.unshift('<p>Mot de passe doit avoir au moins: </p>');
  }
  return errors;
};

export { validatePassword };
