import React from 'react';
import { Fragment } from 'react';
import { Tab } from '@headlessui/react';

function AccountTab({ children, title }) {
  return (
    <Tab as={Fragment}>
      {({ selected }) => (
        <button className="flex w-full justify-between text-left leading-24px outline-none md:text-18px lg:text-21px lg:leading-24px">
          <div className="flex items-center">
            <svg
              className={`mr-3 ${selected ? 'text-bright-orange' : 'text-black'}`}
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              {children}
            </svg>
            <div className={`border-b-2 ${selected ? 'border-bright-orange' : 'border-transparent'}`}>{title}</div>
          </div>
          <div className={`ml-4 border-r-2 pb-16 ${selected ? 'border-bright-orange' : 'border-transparent'}`}></div>
        </button>
      )}
    </Tab>
  );
}

export default AccountTab;
