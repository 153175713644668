import React, { useEffect, useRef, useState } from 'react';

import { connectAutoComplete, connectStateResults } from 'react-instantsearch-dom';
import { FicheHit } from '..';

import withRouter from '../../HOC/withRouter';
import useComponentVisible from '../../common/hook/useComponentVisible';

function Autocomplete({
  searchedWord,
  refine,
  currentRefinement,
  navigate,
  handleSearch,
  isResultsPage,
  setSearchedWord = null,
  children,
  hits,
}) {
  const [showResults, setShowResults] = useState(false);
  const timer = useRef(null);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);

  useEffect(() => {
    if (searchedWord) {
      refine(searchedWord);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedWord]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    closeResults();
    if (window.event.ctrlKey || window.event.metaKey) {
      window.open(`/outils-recherche?query=${currentRefinement}`, '_blank', 'popup');
    } else {
      navigate(`/outils-recherche?query=${currentRefinement}`);
    }
  };

  const handleHitClick = (fiche_nid) => {
    if (window.event.ctrlKey || window.event.metaKey) {
      window.open(`/fiche/${fiche_nid}`, '_blank', 'popup');
    } else {
      navigate(`/fiche/${fiche_nid}`);
    }
  };

  const handleKeyUp = (evt) => {
    if (evt.keyCode === 13) {
      handleSearch(currentRefinement);
      return false;
    } else {
      setShowResults(true);
      clearTimeout(timer.current);
      timer.current = setTimeout(closeResults, 5000);
      if (setSearchedWord) {
        setSearchedWord(currentRefinement);
      }
    }
  };

  const closeResults = () => {
    setShowResults(false);
    setIsComponentVisible(true);
  };

  useEffect(() => {
    if (!isComponentVisible) {
      closeResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible]);

  return isResultsPage ? (
    <div ref={ref}>
      {children}
      <form onSubmit={handleSubmit} onKeyUp={handleKeyUp} className="max-w-4xl">
        <div className="flex items-center justify-between border-b border-black lg:border-none">
          <input
            type="search"
            value={currentRefinement}
            onChange={(event) => refine(event.currentTarget.value)}
            className="block w-full border-none py-4 pl-0 text-28px leading-36px placeholder:text-black focus:outline-none focus:placeholder:text-transparent md:text-28px md:text-36px md:leading-36px md:leading-48px lg:mb-6 lg:mb-0 lg:hidden lg:w-3/4 lg:border-b lg:border-black lg:py-6"
            placeholder="Entrer un mot..."
          ></input>
          <input
            type="search"
            value={currentRefinement}
            onChange={(event) => refine(event.currentTarget.value)}
            className="mb-6 hidden w-full border-x-0 border-t-0 border-b border-black py-2 pl-0 text-28px leading-36px placeholder:text-black focus:border-black focus:outline-none focus:placeholder:text-transparent lg:mb-0 lg:block lg:w-3/4 lg:text-36px lg:leading-48px"
            placeholder="Entrer un mot, un thème, une idée..."
          ></input>
          <span className="flex items-center pl-2 pr-4 lg:hidden">
            <button type="submit">
              <svg width="1.5em" height="1.5em" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0c.41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14z"
                ></path>
              </svg>
            </button>
          </span>
          <div className="hidden w-1/4 lg:block lg:pl-8">
            <button
              type="submit"
              className="link-light-grey flex items-center border border-black py-3.5 px-4 text-14px leading-20px lg:py-4 lg:px-6 lg:text-21px lg:leading-24px"
            >
              <div>
                <svg className="mr-2 lg:mr-4" width="1em" height="1em" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0c.41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14z"
                  ></path>
                </svg>
              </div>
              <div>Rechercher</div>
            </button>
          </div>
        </div>
      </form>
      <Results showResults={showResults}>
        <ul className="shadow-xl">
          {hits.slice(0, 10).map((hit, i) => (
            <FicheHit key={`fiche-hit-${i}`} hit={hit} handleClick={handleHitClick} />
          ))}
        </ul>
      </Results>
    </div>
  ) : (
    <div ref={ref} className="mb-12">
      {children}

      <form onSubmit={handleSubmit} className="max-w-4xl border-b border-black">
        <div className="flex items-center justify-between">
          <input
            type="search"
            value={currentRefinement}
            onChange={(event) => refine(event.currentTarget.value)}
            className="block w-full border-none bg-neutral-200 py-6 pl-0 text-24px leading-30px placeholder:text-black focus:outline-none focus:placeholder:text-transparent lg:hidden lg:text-36px lg:text-45px lg:leading-48px lg:leading-56px"
            placeholder="Entrer un mot, un thème..."
          ></input>
          <input
            type="search"
            value={currentRefinement}
            onChange={(event) => refine(event.currentTarget.value)}
            className="hidden w-full border-none bg-neutral-200 py-2 pl-0 text-24px leading-30px placeholder:text-black focus:outline-none focus:placeholder:text-transparent lg:block lg:text-36px lg:text-45px lg:leading-48px lg:leading-56px"
            placeholder="Entrer un mot, un thème, une idée..."
          ></input>
          <span className="flex items-center pl-2 pr-4">
            <button type="submit">
              <svg width="1.5em" height="1.5em" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0c.41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14z"
                ></path>
              </svg>
            </button>
          </span>
        </div>
      </form>

      <ResultsHomePage showResults={showResults}>
        <ul>
          {hits.slice(0, 10).map((hit, i) => (
            <FicheHit key={`fiche-hit-${i}`} hit={hit} handleClick={handleHitClick} />
          ))}
        </ul>
      </ResultsHomePage>
    </div>
  );
}

const Results = connectStateResults(({ searchState, children, showResults }) =>
  showResults && searchState && searchState.query ? (
    <div className="relative max-w-4xl">
      <div className="absolute z-50 w-full bg-white lg:w-3/4">{children}</div>
    </div>
  ) : (
    <div className="relative max-w-4xl">
      <div className="absolute z-50 w-full bg-white lg:w-3/4"></div>
      <div className="w-1/4"></div>
    </div>
  ),
);

const ResultsHomePage = connectStateResults(({ searchState, children }) =>
  searchState && searchState.query ? (
    <div className="relative max-w-4xl">
      <div className="absolute z-50 w-full bg-white">{children}</div>
    </div>
  ) : (
    <div className="relative max-w-4xl">
      <div className="absolute z-50 w-full bg-white"></div>
      <div className="w-1/4"></div>
    </div>
  ),
);

const AutocompleteSearchbar = connectAutoComplete(withRouter(Autocomplete));
export default AutocompleteSearchbar;
