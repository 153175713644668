import React, { useEffect, useState } from 'react';
import { updateUser } from '../../api';
import { useAuthContext } from '../../common/context/authContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function AccountInfos({ user }) {
  const [isEditMode, setEditMode] = useState(false);
  const [accountUser, setAccountUser] = useState(user);
  const navigate = useNavigate();
  const auth = useAuthContext();

  useEffect(() => {
    setAccountUser(user);
  }, [user]);

  const toggleEditMode = () => {
    setEditMode(!isEditMode);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('accessToken');
    updateUser(accountUser, token)
      .then(() => {
        toggleEditMode();
      })
      .catch(() => {
        navigate('/account');
        toast.error('Veuillez vous reconnecter');
      });
  };

  const handleChange = (e) => {
    setAccountUser({ ...accountUser, [e.target.name]: e.target.value });
  };

  const handleLogout = () => {
    auth.signOut();
  };

  return (
    <div className="text-16px leading-20px md:text-20px md:leading-24px">
      {isEditMode ? (
        <>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="font-semibold">
                Prénom <span className="text-dark-orange">*</span>
              </div>
              <input
                className="mb-6 mt-2 w-full border-x-0 border-b border-t-0 border-black px-0 pb-2 text-20px leading-24px outline-none"
                type="text"
                name="firstname"
                value={accountUser.firstname}
                placeholder="Prénom"
                onChange={handleChange}
              />
              <div className="font-semibold">
                Nom <span className="text-dark-orange">*</span>
              </div>
              <input
                className="mb-6 mt-2 w-full border-x-0 border-b border-t-0 border-black px-0 pb-2 text-20px leading-24px outline-none md:mb-8"
                type="text"
                name="lastname"
                value={accountUser.lastname}
                placeholder="Nom"
                onChange={handleChange}
              />

              <div className="font-semibold">Biographie</div>
              <textarea
                className="mt-2 min-h-[8rem]  w-full border-x-0 border-b border-t-0 border-black px-0 pb-2 text-20px leading-24px outline-none md:mb-8"
                name="biography"
                placeholder="Biographie"
                onChange={handleChange}
                value={accountUser.biography}
              ></textarea>
            </div>
            <button
              className="block border border-black py-3 px-6 text-14px leading-20px hover:bg-light-gray md:text-18px md:leading-24px lg:px-10 lg:py-6 lg:text-21px lg:leading-24px"
              type="submit"
            >
              Sauvegarder les modifications
            </button>
          </form>
        </>
      ) : (
        <div>
          <div className="mb-6 text-28px leading-36px md:mb-8 lg:text-36px lg:leading-48px">{`${accountUser.firstname} ${accountUser.lastname}`}</div>
          <div className="mb-6 text-16px leading-20px md:mb-8 md:mb-10 md:text-20px md:leading-24px">
            {accountUser.biography}
          </div>
          <div className="flex gap-4">
            <button
              className="block border border-black py-3 px-6 text-14px leading-20px hover:bg-light-gray md:text-18px md:leading-24px lg:px-10 lg:py-6 lg:text-21px lg:leading-24px"
              onClick={toggleEditMode}
            >
              Éditer le profil
            </button>

            <button
              onClick={handleLogout}
              className="block border border-black py-3 px-6 text-14px leading-20px hover:bg-light-gray md:text-18px md:leading-24px lg:px-10 lg:py-6 lg:text-21px lg:leading-24px"
            >
              Déconnexion
            </button>
          </div>
          {accountUser.isRestricted && (
            <span className="my-3 block text-sm text-red-500">
              Votre profil a été restreint par l'équipe de modération.
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default AccountInfos;
