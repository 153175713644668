import React from 'react';

function Input({ title, type, name, value, onChange }) {
  const handleOnChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div>
      <div className="font-semibold lg:text-20px lg:leading-24px">
        {title} <span className="text-dark-orange">*</span>
      </div>
      <input
        className="mb-4 mt-2 w-full border-x-0 border-t-0 border-b border-black p-2 pl-0 text-20px leading-24px outline-none focus:border-black focus:ring-transparent"
        type={type}
        name={name}
        value={value}
        onChange={handleOnChange}
        required
      />
    </div>
  );
}

export default Input;
