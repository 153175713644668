import React from 'react';

function Link({ text, url }) {
  return (
    <p>
      <a className="link external-link" href={url} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    </p>
  );
}

export default Link;
