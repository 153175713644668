import React from 'react';

function ProfilInfos({ profil }) {
  return (
    <>
      <h2 className="mb-6 md:mb-8 lg:mb-10">{`${profil.firstname} ${profil.lastname}`}</h2>
      <div className="text-16px leading-20px md:text-20px md:leading-24px lg:text-24px lg:leading-30px">
        {profil.biography}
      </div>
    </>
  );
}

export default ProfilInfos;
