import React, { useState } from 'react';
import { Listbox } from '@headlessui/react';
import { AnthologiesListProfile, PasswordForm, AccountInfos, AnthologieEditor } from '..';
import { useAuthContext } from '../../common/context/authContext';

const tabs = [
  { id: 1, title: 'Mon profil' },
  { id: 2, title: 'Mes anthologies' },
  { id: 3, title: 'Changer le mot de passe' },
];

function SelectTabs({ userPrivateAnthologies, userPublicAnthologies, getAnthologies }) {
  const { user, accessToken } = useAuthContext();
  const [showEditor, setShowEditor] = useState(false);
  const [selected, setSelected] = useState(tabs[0]);

  const toggleEditor = () => {
    setShowEditor(!showEditor);
    getAnthologies();
  };

  const renderIcon = (tab) => {
    switch (tab.id) {
      case 1:
        return (
          <path
            fill="currentColor"
            d="M12 5.9a2.1 2.1 0 1 1 0 4.2a2.1 2.1 0 0 1 0-4.2m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4s4-1.79 4-4s-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
          ></path>
        );
      case 2:
        return <path fill="currentColor" d="M19 3H5v18l7-3l7 3V3zm-2 15l-5-2.18L7 18V5h10v13z"></path>;
      case 3:
        return (
          <path
            fill="currentColor"
            d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2zM9 8V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9z"
          ></path>
        );
      default:
    }
  };

  const renderSection = (tab) => {
    switch (tab.id) {
      case 1:
        return (
          <div className="mt-16">
            <AccountInfos user={user} access_token={accessToken} />
          </div>
        );
      case 2:
        return (
          <>
            {userPublicAnthologies && userPublicAnthologies.length ? (
              <div className="mt-16">
                <h3 className="mb-8">Mes anthologies publiques</h3>
                <div className="mb-8">
                  <AnthologiesListProfile anthologies={userPublicAnthologies} reloadUserAnthologies={getAnthologies} />
                </div>
              </div>
            ) : (
              ''
            )}
            {userPrivateAnthologies && userPrivateAnthologies.length ? (
              <div className="mt-16">
                <h3 className="mb-8">Mes anthologies privées</h3>
                <div className="mb-8">
                  <AnthologiesListProfile anthologies={userPrivateAnthologies} reloadUserAnthologies={getAnthologies} />
                </div>
              </div>
            ) : (
              ''
            )}
            <button
              onClick={toggleEditor}
              className="mb-8 flex items-center justify-between border border-black p-4 text-14px leading-20px hover:bg-light-gray md:mb-0 lg:text-21px lg:leading-24px"
            >
              <svg className="mr-2 lg:mr-4" width="1.25em" height="1.25em" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19 12.998h-6v6h-2v-6H5v-2h6v-6h2v6h6z"></path>
              </svg>
              <div>Créer une anthologie</div>
            </button>
            <AnthologieEditor closeEditor={toggleEditor} show={showEditor} />
          </>
        );
      case 3:
        return (
          <div className="mx-auto mt-16 max-w-sm">
            <PasswordForm setSelectedIndex={(foo) => {}} />
          </div>
        );
      default:
    }
  };

  return (
    <div>
      <Listbox value={selected} onChange={setSelected}>
        <Listbox.Button className="relative z-10 flex w-full items-center justify-between border border-black bg-white py-3 px-6 text-left text-14px leading-20px">
          <div className="flex items-center">
            <div>
              <svg className="mr-2 text-bright-orange" width="1em" height="1em" viewBox="0 0 24 24">
                {renderIcon(selected)}
              </svg>
            </div>
            <div>{selected.title}</div>
          </div>
          <div>
            <svg width="1em" height="1em" viewBox="0 0 24 24">
              <path fill="currentColor" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6l-6-6l1.41-1.41z"></path>
            </svg>
          </div>
        </Listbox.Button>

        <div className="relative w-full">
          <Listbox.Options className="absolute z-10 w-full">
            {tabs.map((tab, tabIdx) => (
              <Listbox.Option
                key={tabIdx}
                className={({ active, selected }) =>
                  `${active ? 'hover:bg-light-gray' : 'bg-white'} ${selected ? 'hidden' : 'block'}`
                }
                value={tab}
              >
                <div className="flex cursor-pointer items-center border-x border-b border-black px-4 py-3 text-14px leading-20px">
                  <div>
                    <svg className="mr-2 text-bright-orange" width="1em" height="1em" viewBox="0 0 24 24">
                      {renderIcon(tab)}
                    </svg>
                  </div>
                  <div>{tab.title}</div>
                </div>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>

      <div>{renderSection(selected)}</div>
    </div>
  );
}

export default SelectTabs;
