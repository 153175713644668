import React from 'react';
import { connectHits } from 'react-instantsearch-dom';
import { FichePreview, GridResults } from '..';

function Hits({ hits }) {
  return (
    <GridResults>
      {hits.map((hit) => (
        <FichePreview key={hit.objectID} hit={hit} />
      ))}
    </GridResults>
  );
}

const SearchResults = connectHits(Hits);

export default SearchResults;
