import React, { Component } from 'react';
import withRouter from '../../HOC/withRouter';

class AnthologiePreview extends Component {
  handleClick = () => {
    this.props.navigate(`/anthologie/${this.props.anthologie._id}`);
  };
  render() {
    let { anthologie } = this.props;
    return (
      <div className="link-bright-orange h-0 w-full cursor-pointer bg-light-gray pb-[100%] " onClick={this.handleClick}>
        <div className="p-8">
          {anthologie.is_featured && (
            <div className="mb-4 flex items-center text-14px leading-20px md:text-18px md:leading-24px">
              <div className="mr-3 flex flex-col items-center">
                <hr className="border-0.5 w-6 border-black" />
              </div>
              <div>Vedette</div>
            </div>
          )}
          <div className="mb-4 break-words text-24px leading-30px line-clamp-6 sm:line-clamp-5 md:text-28px md:leading-36px lg:line-clamp-3 xl:text-36px xl:leading-48px xl:line-clamp-4">
            {anthologie.name}
          </div>
          <div className="flex items-center text-14px leading-20px md:text-18px md:leading-24px">
            <svg className="mr-2" width="1em" height="1em" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12 5.9a2.1 2.1 0 1 1 0 4.2a2.1 2.1 0 0 1 0-4.2m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4s4-1.79 4-4s-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
              ></path>
            </svg>
            <div className="line-clamp-1">{`${anthologie.creator.firstname} ${anthologie.creator.lastname}`}</div>
          </div>
          {/* <div>
            {anthologie.fiches.length} fiche
            {anthologie.fiches.length > 1 ? "s" : ""}
          </div> */}
        </div>
      </div>
    );
  }
}

export default withRouter(AnthologiePreview);
