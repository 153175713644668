import React, { useEffect, useState } from 'react';
import { createAnthologie, deleteAnthologie, getAnthologie, updateAnthologie } from '../../api';
import { useAuthContext } from '../../common/context/authContext';

function AnthologieEditor({ id, closeEditor, show, fiches: ficheData }) {
  const { user, isAdmin } = useAuthContext();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [creator, setCreator] = useState(user?._id ? user._id : '');
  const [fiches, setFiches] = useState(user?._id ? user._id : '');

  const queryAnthologie = () => {
    getAnthologie(id).then((res) => {
      const { name, description, is_public, is_featured, fiches, creator } = res;
      setName(name);
      setDescription(description);
      setIsPublic(is_public);
      setIsFeatured(is_featured);
      setCreator(creator);
      setFiches(ficheData ? [...fiches, ...ficheData] : fiches);
    });
  };

  useEffect(() => {
    if (id && show) {
      queryAnthologie();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, show]);

  useEffect(() => {
    if (user?._id) setCreator(user._id);
  }, [user]);

  const resetData = () => {
    setName('');
    setDescription('');
    setIsPublic(false);
    setIsFeatured(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = { name, description, is_public: isPublic, is_featured: isFeatured, creator, fiches };
    if (id) {
      data._id = id;
      updateAnthologie(data).then(() => {
        resetData();
        queryAnthologie();
        if (closeEditor) closeEditor();
      });
    } else {
      createAnthologie(data).then((res) => {
        resetData();
        if (closeEditor) closeEditor();
      });
    }
  };

  const handleDeleteAnthologie = async () => {
    if (window.confirm('Voulez vous réellement supprimer cette anthologie?')) {
      await deleteAnthologie(id);
      if (closeEditor) closeEditor();
    }
  };

  const handleIsFeatured = (e) => {
    const value = e.target.checked;
    setIsFeatured(value);
    if (value) {
      setIsPublic(e.target.checked);
    }
  };

  const handleIsPublic = (e) => {
    const value = e.target.checked;
    setIsPublic(e.target.checked);
    if (!value) {
      setIsFeatured(value);
    }
  };

  return (
    <>
      <div className={`fixed inset-0 h-full w-full bg-black opacity-20 ${show ? 'block' : 'hidden'}`}></div>
      <div
        className={`fixed top-0 right-0 z-50 h-full max-w-2xl overflow-y-auto bg-white px-6 pt-10 pb-4 ${
          show ? 'block' : 'hidden'
        }`}
      >
        <div className="mb-12">
          <div className="mb-10 flex items-center justify-between text-24px leading-30px md:text-28px md:leading-36px">
            {id ? (
              <div className="mr-8 md:mr-0">Modifier une anthologie</div>
            ) : (
              <div className="mr-8 md:mr-0">Créer une anthologie</div>
            )}
            <button onClick={closeEditor}>
              <svg width="1em" height="1em" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41z"
                ></path>
              </svg>
            </button>
          </div>

          <div className="mb-10 text-16px leading-20px md:text-20px md:leading-24px">
            Entrer les informations pour votre nouvelle anthologie.
          </div>

          <form onSubmit={handleSubmit} className="flex flex-col">
            <div className="mb-8 text-16px leading-20px md:text-20px md:leading-24px">
              <label className="mb-4 block font-semibold" htmlFor="anthologie_name">
                Titre <span className="text-dark-orange">*</span>
              </label>
              <input
                className="w-full border-x-0 border-b border-t-0 border-black px-0 pb-3 text-16px leading-20px outline-none md:text-20px md:leading-24px"
                type="text"
                name="name"
                id="anthologie_name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-8 text-16px leading-20px md:text-20px md:leading-24px">
              <label className="mb-4 block font-semibold" htmlFor="anthologie_description">
                Description
              </label>
              <textarea
                className="mb-2 w-full border-x-0 border-b border-t-0 border-black px-0 pb-3 text-16px leading-20px outline-none md:text-20px md:leading-24px"
                name="description"
                id="anthologie_description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="mb-8 flex items-center text-16px leading-20px md:text-21px md:leading-24px">
              <input
                className="mr-3 text-bright-orange"
                type="checkbox"
                name="is_public"
                checked={isPublic}
                onChange={handleIsPublic}
              />
              <label htmlFor="anthologie_ispublic">Anthologie publique</label>
            </div>
            {isAdmin() ? (
              <div className="mb-12 text-16px leading-20px md:text-21px md:leading-24px">
                <input
                  className="mr-3"
                  type="checkbox"
                  name="is_featured"
                  checked={isFeatured}
                  onChange={handleIsFeatured}
                />
                <label htmlFor="anthologie_is_featured">Anthologie vedette</label>
              </div>
            ) : (
              ''
            )}

            <div className="flex items-center gap-4 text-14px leading-20px md:text-18px md:leading-24px lg:text-21px">
              <button className="border border-black px-6 py-4 hover:bg-light-gray lg:px-10 lg:py-6" type="submit">
                Sauvegarder
              </button>
              {id && (
                <button
                  className="border border-black px-6 py-4 hover:bg-light-gray lg:px-10 lg:py-6"
                  onClick={handleDeleteAnthologie}
                >
                  Supprimer
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AnthologieEditor;
