import React from 'react';
import { connectHighlight } from 'react-instantsearch-dom';

const Highlight = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  return (
    <span>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <mark
            className="bg-transparent text-dark-orange"
            key={index}
            dangerouslySetInnerHTML={{
              __html: part.value.length > 199 ? part.value.substring(0, 200) + '...' : part.value,
            }}
          />
        ) : (
          <span
            key={index}
            dangerouslySetInnerHTML={{
              __html: part.value.length > 199 ? part.value.substring(0, 200) + '...' : part.value,
            }}
          />
        ),
      )}
    </span>
  );
};
const FicheHighlight = connectHighlight(Highlight);
export default FicheHighlight;
