import React from 'react';

import { FicheHighlight } from '..';
import { Link } from 'react-router-dom';

function FichePreview({ hit }) {
  return (
    <>
      {hit && (
        <Link to={`/fiche/${hit.encodage_nid}`}>
          <div className="link-bright-orange h-0 h-full w-full cursor-pointer bg-light-gray pb-[100%]">
            <div className="p-8 text-14px leading-20px lg:text-18px lg:leading-24px">
              <div className="mb-4 flex items-center text-14px leading-20px md:text-18px md:leading-24px">
                <div className="mr-3 flex flex-col items-center">
                  <hr className="border-0.5 w-6 border-black" />
                </div>
                <div>{hit.type}</div>
              </div>
              <h3 className="mb-4 line-clamp-5 sm:line-clamp-6 md:line-clamp-4 lg:line-clamp-5 xl:line-clamp-4 2xl:line-clamp-5">
                <FicheHighlight attribute="title" hit={hit} />
              </h3>
              <div className="mb-3 flex items-center">
                <svg className="mr-2 flex-none" width="1em" height="1em" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12 5.9a2.1 2.1 0 1 1 0 4.2a2.1 2.1 0 0 1 0-4.2m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4s4-1.79 4-4s-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
                  ></path>
                </svg>
                <span className="line-clamp-1 sm:line-clamp-6 md:line-clamp-1 lg:line-clamp-2 xl:line-clamp-1 2xl:line-clamp-2">
                  {hit.authors.map((author, i) => (
                    <span
                      key={`auteur-${i}`}
                      dangerouslySetInnerHTML={{
                        __html: `${author.firstname} ${author.lastname}${
                          hit.authors.length > 1 && i < hit.authors.length - 1 ? ', ' : ''
                        }`,
                      }}
                    ></span>
                  ))}
                </span>
              </div>
              <div className="flex items-center">
                <svg className="mr-2" width="1em" height="1em" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z"
                  ></path>
                </svg>
                <div>
                  <FicheHighlight attribute="source" hit={hit} />
                </div>
              </div>
            </div>
          </div>
        </Link>
      )}
    </>
  );
}

export default FichePreview;
