import React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

const ClearRefinements = ({ items, refine }) => (
  <div className="max-w-md px-6 xl:px-0">
    <button
      className="mt-4 w-full border border-black px-6 py-4 text-14px leading-20px hover:bg-light-gray lg:text-21px lg:leading-24px"
      onClick={() => refine(items)}
      disabled={!items.length}
    >
      Réinitialiser
    </button>
  </div>
);
const ReinitialiserRefinements = connectCurrentRefinements(ClearRefinements);
export default ReinitialiserRefinements;
