import React from 'react';
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    const path = props.path ? props.path : '/';
    const match = useMatch(path);

    return (
      <Component
        {...props}
        navigate={navigate}
        location={location}
        match={match}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter;
