import React from 'react';

function Button({ value, disabled }) {
  return (
    <input
      className="link-black  w-full cursor-pointer border border-black bg-black py-4 text-center text-21px leading-24px text-white disabled:cursor-not-allowed disabled:bg-custom-gray"
      type="submit"
      value={value}
      disabled={disabled}
    />
  );
}

export default Button;
