import React, { Component } from 'react';
import Helmet from 'react-helmet';

class Credits extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Crédits - Encodage</title>
        </Helmet>
        <div className="mb-20 mt-16 2xl:mx-40">
          <h1 className="mb-16 md:pl-12 lg:pl-20 2xl:mb-8">Crédits</h1>
          <div className="ml-auto max-w-xl lg:max-w-4xl">
            <h4 className="mb-16 2xl:mb-8">
              La plateforme numérique Encodage a été développée en 2022 par l'équipe médiatique du Laboratoire NT2 du
              Département d'études littéraires de l'Université du Québec à Montréal (UQAM).
            </h4>

            <div className="credits">
              <h6>Responsables du projet</h6>
              <p>Bertrand Gervais et Nathalie Lacelle</p>

              <h6>Coordination du projet</h6>
              <p>Sylvain Aubé, Sarah Grenier-Millette et Elaine Després</p>

              <h6>Développement et programmation Web</h6>
              <p>Ariane Guay et Dany Viens</p>

              <h6>Design web</h6>
              <p>
                <a className="link external-link" href="http://www.sgiroux.net/" rel="noreferrer" target="_blank">
                  Stéphanie Giroux
                </a>
              </p>

              <h6>Intégration du design</h6>
              <p>John Boyle-Singfield</p>

              <h6>Aide à la programmation Web</h6>
              <p>Koumbit</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Credits;
