import React from 'react';
import { Link } from 'react-router-dom';
import withRouter from '../../../HOC/withRouter';
import MobileMenu from './MobileMenu';

function Header({ location, isLoggedIn }) {
  const { pathname } = location;
  return (
    <div className="my-8 mx-4 flex items-center justify-between md:my-12 md:mx-12">
      <div className="relative z-30 text-28px font-bold leading-35px">
        <Link to="/">Encodage</Link>
      </div>

      <div className="hidden gap-12 text-18px leading-24px md:flex lg:gap-20 lg:text-21px">
        {!['/', '/outils-recherche'].includes(pathname) && (
          <div>
            <Link to="/outils-recherche">
              <svg width="1em" height="1em" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0c.41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14z"
                ></path>
              </svg>
            </Link>
          </div>
        )}
        <div className={pathname === '/anthologies' ? 'border-b-2 border-bright-orange' : 'border-none'}>
          <Link to="/anthologies">Anthologies</Link>
        </div>
        <div className={pathname === '/account' ? 'border-b-2 border-bright-orange' : 'border-none'}>
          {isLoggedIn ? <Link to="/account">Compte</Link> : <Link to="/account">Connexion</Link>}
        </div>
        <div className={pathname === '/a-propos' ? 'border-b-2 border-bright-orange' : 'border-none'}>
          <Link to="/a-propos">À propos</Link>
        </div>
      </div>

      <div className="block md:hidden">
        <MobileMenu />
      </div>
    </div>
  );
}

export default withRouter(Header);
