import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { AutocompleteSearchbar } from '../components';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: false,
      searchedWord: '',
    };
  }
  handleSearch = (searched) => {
    this.setState({ searchedWord: searched });
  };

  componentDidMount = () => {
    this.timeout = setTimeout(this.showResults, 15000);
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Encodage</title>
          <style type="text/css">{`
            body {
                background-color: #E5E5E5;
            }
          `}</style>
        </Helmet>
        <p className="mb-12 max-w-xl text-20px leading-24px md:mt-10">
          Encodage est un outil de recherche et d’anthologisation sur l’imaginaire puisant dans l'écosystème numérique
          sur le contemporain du Centre Figura.
        </p>
        <AutocompleteSearchbar handleSearch={this.handleSearch}></AutocompleteSearchbar>
      </>
    );
  }
}
