/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import withRouter from '../HOC/withRouter';
import { getProfil, getUserPublicAnthologies } from '../api';
import { AnthologiesListFiche, ProfilInfos } from '../components';

function AccountPage({ match }) {
  const [{ profilId }] = useState(match.params);
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [user_anthologies, setUser_anthologies] = useState([]);

  useEffect(() => {
    getProfil(profilId).then((user) => {
      if (user) {
        setUser(user);
      } else {
        setError('Aucun usager disponible');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      getAnthologies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getAnthologies = () => {
    if (user && user._id) {
      getUserPublicAnthologies(user._id).then((res) => {
        setUser_anthologies(res);
      });
    }
  };

  if (error) {
    return <h2>{error}</h2>;
  }

  return (
    <>
      {user ? (
        <>
          <div className="mt-16 mb-20">
            <div className="mx-auto w-full max-w-3xl md:px-0 lg:flex lg:max-w-none lg:justify-between">
              <div className="lg:mb-0 lg:w-2/6"></div>

              <div className="mx-auto max-w-3xl lg:w-4/6">
                <ProfilInfos profil={user} />

                <hr className="mt-10 mb-16 border-black" />
                {user_anthologies && !!user_anthologies.length && (
                  <>
                    <div className="max-w-xl text-16px leading-20px md:text-20px md:leading-24px lg:max-w-2xl lg:text-16px lg:leading-20px"></div>
                    <div className="mb-8 md:mb-16 lg:mb-20">
                      <div className="mb-10 text-24px leading-30px md:text-28px md:leading-36px">
                        Anthologies de cet utilisateur.trice :
                      </div>
                      <AnthologiesListFiche anthologies={user_anthologies} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        'Chargement...'
      )}
    </>
  );
}

export default withRouter(AccountPage);
