import React, { useEffect, useState } from 'react';
import { Loading } from 'react-loading-dot';
import { sendResetPasswordEmail } from '../../api';
import { Form, Input, Button } from '..';
import { useAuthContext } from '../../common/context/authContext';
import { toast } from 'react-toastify';
import { validatePassword } from '../../common/helpers/PasswordHelper';
import { constants } from '../../common/constants/constant';
import ReCAPTCHA from 'react-google-recaptcha';

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [isSignup, setIsSignUp] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isHuman, setIsHuman] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const auth = useAuthContext();

  const setLogin = () => {
    setIsForgotPassword(false);
    setIsSignUp(false);
    setErrorMessage('');
  };

  const setSignup = () => {
    setIsForgotPassword(false);
    setIsSignUp(true);
    setErrorMessage('');
    setPassword('');
  };

  const setForgotPassword = (e) => {
    e.preventDefault();
    setIsForgotPassword(true);
    setIsSignUp(false);
    setErrorMessage('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSignup) {
      const errors = validatePassword(password);
      if (errors.length > 0) {
        setErrorMessage(errors.join('\n'));
      } else {
        signUp();
      }
    } else signIn();
  };

  const signIn = () => {
    //Get the data to send
    const data = {
      email,
      password,
    };
    setLoading(true);
    auth.signIn(data).then((res) => {
      setLoading(false);
      if (res.message) {
        toast.error(res.message);
      }
    });
  };

  const signUp = () => {
    const data = {
      email,
      password,
      firstname,
      lastname,
    };
    auth.signUp(data).then((res) => {
      if (res.message) {
        setErrorMessage(res.message);
      } else if (res.readyState === 4 && res.status === 0) {
        setErrorMessage('Problème de serveur. Veuillez réessayer plus tard.');
      }
    });
  };

  useEffect(() => {
    let valid;
    if (isSignup) {
      valid = email.length > 0 && password.length > 0 && firstname.length > 0 && lastname.length > 0 && isHuman;
    } else {
      valid = email.length > 0 && password.length > 0;
    }
    setIsFormValid(valid);
  }, [email, password, isSignup, isHuman, firstname, lastname]);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCaptcha = (value) => {
    if (value) {
      setIsHuman(true);
    } else {
      setIsHuman(false);
    }
  };

  const registerForm = () => {
    return (
      <>
        <Form onSubmit={handleSubmit}>
          <Input
            className="lg:text-20px lg:leading-24px"
            title="Nom"
            name="lastname"
            value={lastname}
            type="text"
            onChange={setLastname}
          />
          <Input
            className="lg:text-20px lg:leading-24px"
            title="Prénom"
            name="firstname"
            value={firstname}
            type="text"
            onChange={setFirstname}
          />
          <Input
            className="lg:text-20px lg:leading-24px"
            title="Courriel"
            name="email"
            value={email}
            type="email"
            onChange={setEmail}
          />

          <div className="mb-2">
            <div className="font-semibold lg:text-20px lg:leading-24px">
              Mot de passe <span className="text-dark-orange">*</span>
            </div>
            <div className="flex items-center justify-between border-b border-black">
              <input
                className="mt-2 w-full border-none pl-0 text-20px leading-24px outline-none focus:border-none focus:ring-transparent"
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
              />
              <svg
                className="cursor-pointer opacity-50 hover:opacity-100"
                width="1.25em"
                height="1.25em"
                viewBox="0 0 24 24"
                onClick={togglePassword}
              >
                <path
                  fill="currentColor"
                  d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3z"
                ></path>
              </svg>
            </div>
          </div>

          <ReCAPTCHA
            className="w-full p-4"
            sitekey="6LfDjnciAAAAAKtnOOz-ePi0raROrQzv8TWKPaSl"
            hl="fr-CA"
            onChange={handleCaptcha}
          />

          <Button value="M'inscrire" disabled={!isFormValid} />
          <input
            className="mt-4 w-full cursor-pointer border border-black py-4 text-center text-21px leading-24px"
            value="Je veux me connecter"
            onClick={setLogin}
            readOnly
          />
        </Form>
        {errorMessage && (
          <div className="font-bold text-red-500" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
        )}
      </>
    );
  };
  const forgotPasswordForm = () => {
    return (
      <Form onSubmit={forgotPassword} id="forgotPassword">
        <Input title="Courriel" name="email" value={email} type="email" onChange={setEmail} />
        <Button form="forgotPassword" type="submit" value="M'envoyer un lien de réinitialisation" />
        <input
          className="mt-4 mb-8 w-full cursor-pointer border border-black py-4 text-center text-21px leading-24px"
          value="Je veux me connecter"
          onClick={setLogin}
          readOnly
        />
      </Form>
    );
  };

  const forgotPassword = (e) => {
    e.preventDefault();

    const id = toast.loading('Veuillez patentier...');

    sendResetPasswordEmail({ email }).then((res) => {
      if (res.error) {
        toast.update(id, {
          render: res.error,
          type: 'error',
          ...constants.toastLoadingOptions,
        });
      } else {
        toast.update(id, {
          render: 'Courriel envoyé!',
          type: 'default',
          ...constants.toastLoadingOptions,
        });
        setIsForgotPassword(false);
        setIsSignUp(false);
      }
    });
  };

  const loginForm = () => {
    return (
      <>
        <Form onSubmit={handleSubmit}>
          <Input title="Courriel" name="email" value={email} type="email" onChange={setEmail} />

          <div className="mb-12">
            <div className="font-semibold lg:text-20px lg:leading-24px">
              Mot de passe <span className="text-dark-orange">*</span>
            </div>
            <div className="flex items-center justify-between border-b border-black">
              <input
                className="mt-2 w-full border-none pl-0 text-20px leading-24px outline-none focus:border-none focus:ring-transparent"
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <svg
                className="cursor-pointer opacity-50 hover:opacity-100"
                width="1.25em"
                height="1.25em"
                viewBox="0 0 24 24"
                onClick={togglePassword}
              >
                <path
                  fill="currentColor"
                  d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3z"
                ></path>
              </svg>
            </div>
          </div>

          <Button value="Connexion" disabled={!isFormValid} readOnly />
          <input
            className="link-light-grey mt-4 mb-4 w-full cursor-pointer border border-black py-4 text-center text-21px leading-24px"
            value="Je veux m'inscrire"
            onClick={setSignup}
            readOnly
          />
          <div className="font-18px text-center leading-24px">
            <input
              className="link-light-grey w-full cursor-pointer py-4 px-2 text-center lg:text-18px lg:leading-24px"
              value="J’ai oublié mon mot de passe"
              onClick={setForgotPassword}
              readOnly
            />
          </div>
          {errorMessage && (
            <div className="font-bold text-red-500" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
          )}
        </Form>
      </>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {loading ? (
        <div>
          <Loading background={'rgb(189,189,189)'} />
        </div>
      ) : (
        <div>{isForgotPassword ? forgotPasswordForm() : isSignup ? registerForm() : loginForm()}</div>
      )}
    </div>
  );
}

export default LoginForm;
