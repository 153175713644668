import React, { useEffect, useState } from 'react';
import { getAllFeaturedAnthologies, getAllPublicAnthologies } from '../api';
import { AnthologieEditor, AnthologiesList } from '../components';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useAuthContext } from '../common/context/authContext';

function AnthologiesListPage() {
  const { isLoggedIn } = useAuthContext();

  const [public_anthologies, setPublic_anthologies] = useState([]);
  const [featured_anthologies, setFeatured_anthologies] = useState([]);
  const [show_editor, setShow_editor] = useState(false);

  useEffect(() => {
    verifyAnthologies();
  }, []);

  const verifyAnthologies = () => {
    getAllFeaturedAnthologies().then((res) => setFeatured_anthologies(res));
    getAllPublicAnthologies().then((res) => setPublic_anthologies(res));
  };

  const setEditorState = () => {
    setShow_editor(!show_editor);
    verifyAnthologies();
  };

  return (
    <>
      <Helmet>
        <title>Anthologies - Encodage</title>
      </Helmet>
      <div className="mt-16 mb-20">
        <div>
          {featured_anthologies && featured_anthologies.length ? (
            <section>
              <div className="mb-8 gap-6 md:mb-12 md:flex md:items-center md:justify-between">
                <h1 className="mb-10 md:mb-0">Anthologies en vedette</h1>
                {isLoggedIn ? (
                  <button
                    onClick={setEditorState}
                    className="flex flex-none items-center justify-between border border-black p-4 text-14px leading-20px hover:bg-light-gray lg:text-21px lg:leading-24px"
                  >
                    <svg className="mr-2 lg:mr-4" width="1.25em" height="1.25em" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M19 12.998h-6v6h-2v-6H5v-2h6v-6h2v6h6z"></path>
                    </svg>
                    <div>Créer une anthologie</div>
                  </button>
                ) : (
                  <Link
                    className="flex items-center justify-between border border-black p-4 text-14px leading-20px hover:bg-light-gray lg:text-21px lg:leading-24px"
                    to="/account"
                  >
                    Créer un compte
                  </Link>
                )}
              </div>
              <AnthologiesList anthologies={featured_anthologies} />
            </section>
          ) : (
            ''
          )}
          {public_anthologies && public_anthologies.length ? (
            <section>
              <hr className="mt-12 mb-4 border-black" />
              <h1 className="mb-10">Toutes les anthologies publiques</h1>
              <AnthologiesList anthologies={public_anthologies} withPager />
            </section>
          ) : (
            ''
          )}
          <AnthologieEditor closeEditor={setEditorState} show={show_editor} />
        </div>
      </div>
    </>
  );
}

export default AnthologiesListPage;
