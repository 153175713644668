import React, { useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import { getUserPrivateAnthologies, getUserPublicAnthologies } from '../../api';
import { AnthologieEditor, SelectTabs, AccountTab, AnthologiesListProfile, PasswordForm, AccountInfos } from '..';
import { useAuthContext } from '../../common/context/authContext';

function MyProfile() {
  const { user, isLoggedIn } = useAuthContext();
  const [userPrivateAnthologies, setUserPrivateAnthologies] = useState([]);
  const [userPublicAnthologies, setUserPublicAnthologies] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (user) {
      getAnthologies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getAnthologies = () => {
    if (user._id) {
      getUserPrivateAnthologies(user._id).then((res) => {
        setUserPrivateAnthologies(res);
      });
      getUserPublicAnthologies(user._id).then((res) => {
        setUserPublicAnthologies(res);
      });
    }
  };

  const toggleEditor = () => {
    setShowEditor(!showEditor);
    getAnthologies();
  };

  return isLoggedIn && user?._id ? (
    <>
      <div className="md:hidden">
        <SelectTabs
          userPublicAnthologies={userPublicAnthologies}
          userPrivateAnthologies={userPrivateAnthologies}
          getAnthologies={getAnthologies}
        />
      </div>

      <div className="hidden md:block">
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <div className="mb-16 mt-16 flex-1 md:flex">
            <Tab.List className="mr-8 flex w-1/3 flex-col items-start border-r border-medium-gray lg:mr-20">
              <AccountTab title="Mon profil">
                <path
                  fill="currentColor"
                  d="M12 5.9a2.1 2.1 0 1 1 0 4.2a2.1 2.1 0 0 1 0-4.2m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4s4-1.79 4-4s-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
                ></path>
              </AccountTab>
              <AccountTab title="Mes anthologies">
                <path fill="currentColor" d="M19 3H5v18l7-3l7 3V3zm-2 15l-5-2.18L7 18V5h10v13z"></path>
              </AccountTab>
              <AccountTab title="Changer le mot de passe">
                <path
                  fill="currentColor"
                  d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2zM9 8V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9z"
                ></path>
              </AccountTab>
            </Tab.List>

            <Tab.Panels className="w-2/3 lg:ml-16">
              <Tab.Panel className="max-w-lg">
                <AccountInfos user={user} />
              </Tab.Panel>
              <Tab.Panel>
                {userPublicAnthologies && userPublicAnthologies.length ? (
                  <>
                    <h3 className="mb-8">Mes anthologies publiques</h3>
                    <div className="mb-8">
                      <AnthologiesListProfile
                        anthologies={userPublicAnthologies}
                        reloadUserAnthologies={getAnthologies}
                      />
                    </div>
                  </>
                ) : (
                  ''
                )}
                {userPrivateAnthologies && userPrivateAnthologies.length ? (
                  <>
                    <h3 className="mb-8">Mes anthologies privées</h3>
                    <div className="mb-8">
                      <AnthologiesListProfile
                        anthologies={userPrivateAnthologies}
                        reloadUserAnthologies={getAnthologies}
                      />
                    </div>
                  </>
                ) : (
                  ''
                )}
                <button
                  onClick={toggleEditor}
                  className="mb-8 flex items-center justify-between border border-black p-4 text-14px leading-20px hover:bg-light-gray md:mb-0 lg:text-21px lg:leading-24px"
                >
                  <svg className="mr-2 lg:mr-4" width="1.25em" height="1.25em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M19 12.998h-6v6h-2v-6H5v-2h6v-6h2v6h6z"></path>
                  </svg>
                  <div>Créer une anthologie</div>
                </button>
              </Tab.Panel>
              <Tab.Panel className="max-w-lg">
                <PasswordForm setSelectedIndex={setSelectedIndex} />
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
      <AnthologieEditor closeEditor={toggleEditor} show={showEditor} />
    </>
  ) : (
    'Chargement...'
  );
}

export default MyProfile;
