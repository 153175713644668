import React, { useEffect, useRef, useState } from 'react';
import { getAnthologie, updateAnthologie } from '../api';
import { Link } from 'react-router-dom';
import withRouter from '../HOC/withRouter';

import { FichePreviewList, ShareButtons, AnthologieEditor, IconText } from '../components';

import { PDFExport } from '@progress/kendo-react-pdf';
import { useAuthContext } from '../common/context/authContext';
import { slugify } from '../common/helpers/Slugify';

function AnthologiePage({ match, navigate }) {
  const { user, isAdmin, isLoggedIn } = useAuthContext();
  let { anthologieId } = match.params;

  if (!anthologieId) {
    window.location = '/';
  }
  const [id] = useState(anthologieId);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState('');
  const [showShare, setShowShare] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [anthologie, setAnthologies] = useState({});

  const printRef = useRef(null);

  useEffect(() => {
    if (id) {
      queryAnthologie(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const queryAnthologie = (id) => {
    getAnthologie(id)
      .then((anthologies) => {
        setAnthologies(anthologies);
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          setError(err.response.data.message);
        } else {
          navigate('/anthologies');
        }
      });
  };

  const toggletEditMode = () => {
    setEditMode(!editMode);
  };

  const closeEditor = () => {
    queryAnthologie(id);
    setEditMode(false);
  };

  const goBack = (e) => {
    navigate('/anthologies');
  };

  const setShareHidden = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setShowShare(false);
    }
  };

  const setShareShow = (e) => {
    setShowShare(true);
  };

  const isAuthor = () => {
    const userId = user._id;
    let is_creator = false;
    let is_contributor = false;
    if (anthologie.creator) {
      is_creator = anthologie.creator._id === userId;
    }
    if (anthologie.contributors && anthologie.contributors.length > 0) {
      is_contributor = anthologie.contributors.find((element) => element._id === userId);
    }
    return is_creator || is_contributor;
  };

  const canEdit = () => {
    return !isExporting && isLoggedIn && (isAuthor() || isAdmin());
  };

  const deleteFicheFromAntho = (fiche_nid) => {
    const fiche = anthologie.fiches.find((el) => el.encodage_nid === fiche_nid);
    const fiche_id = anthologie.fiches.indexOf(fiche);
    anthologie.fiches.splice(fiche_id, 1);

    updateAnthologie(anthologie).then((res) => {
      queryAnthologie(res._id);
    });
  };

  const exportPDF = () => {
    setIsExporting(true);
  };

  useEffect(() => {
    if (printRef.current && isExporting) {
      printRef.current.save();
      setIsExporting(false);
    }
  }, [isExporting, printRef]);

  if (error) {
    return <h2>Ce contenu n'est pas disponible</h2>;
  }

  if (anthologie) {
    const { name, description, fiches, creator } = anthologie;
    return (
      <>
        <div className="mt-6 mb-20">
          <div className="mx-auto max-w-3xl px-4 md:px-0 lg:flex lg:max-w-none lg:justify-between">
            <div className="mb-6 lg:w-2/6">
              <button onClick={goBack}>
                <IconText title="Retour aux anthologies">
                  <svg className="mr-2" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20v-2z"></path>
                  </svg>
                </IconText>
              </button>
            </div>

            <div className="mx-auto max-w-3xl lg:w-4/6" id="anthologie">
              <PDFExport
                ref={printRef}
                paperSize="Legal"
                margin={{
                  top: '0.79in',
                  left: '0.59in',
                  right: '0.59in',
                  bottom: '0.79in',
                }}
                scale={0.6}
                keepTogether="a"
                fileName={name ? slugify(name) : ''}
              >
                <div className="mb-6 flex flex-col md:mb-12 md:flex-row md:items-center md:justify-between">
                  <div className="order-2 flex items-center text-18px leading-24px md:order-1">
                    <hr className="border-0.5 mr-4 w-6 border-black" />
                    <div className="text-21px leading-24px md:text-24px md:leading-30px">Anthologie</div>
                  </div>

                  <div className="right-12 top-0 order-1 mb-12 flex w-fit md:order-2 md:mb-0">
                    {!isExporting && (
                      <>
                        <button onClick={setShareShow}>
                          <IconText title="Partager">
                            <svg className="mr-2" width="1em" height="1em" viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81c1.66 0 3-1.34 3-3s-1.34-3-3-3s-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65c0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"
                              ></path>
                            </svg>
                          </IconText>
                        </button>
                        <ShareButtons
                          show={showShare}
                          setShareHidden={setShareHidden}
                          name={anthologie.name}
                          selector="#anthologie"
                        />

                        <button onClick={exportPDF}>
                          <IconText title="Exporter en .pdf">
                            <svg className="mr-2" width="1em" height="1em" viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m-9.5 8.5c0 .8-.7 1.5-1.5 1.5H7v2H5.5V9H8c.8 0 1.5.7 1.5 1.5v1m5 2c0 .8-.7 1.5-1.5 1.5h-2.5V9H13c.8 0 1.5.7 1.5 1.5v3m4-3H17v1h1.5V13H17v2h-1.5V9h3v1.5m-6.5 0h1v3h-1v-3m-5 0h1v1H7v-1Z"
                              ></path>
                            </svg>
                          </IconText>
                        </button>
                        {canEdit() && (
                          <button onClick={toggletEditMode} className="anthologie-edit-btn">
                            <IconText title="Modifier">
                              <svg className="mr-2" width="1em" height="1em" viewBox="0 0 24 24">
                                <path
                                  fill="currentColor"
                                  d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM21.41 6.34l-3.75-3.75l-2.53 2.54l3.75 3.75l2.53-2.54z"
                                ></path>
                              </svg>
                            </IconText>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <h1 className="title mb-6 md:mb-8 lg:mb-12">{name}</h1>
                <div className="mb-6 md:mb-14">
                  {creator && (
                    <div className="flex items-center text-14px leading-20px md:text-21px md:leading-24px">
                      <svg className="mr-3 opacity-40" width="1em" height="1em" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M12 5.9a2.1 2.1 0 1 1 0 4.2a2.1 2.1 0 0 1 0-4.2m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4s4-1.79 4-4s-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
                        ></path>
                      </svg>
                      <div className="lg:border-b lg:border-black lg:pb-0.5">
                        <Link to={`/profil/${creator._id}`}>{`${creator.firstname} ${creator.lastname}`}</Link>
                      </div>
                    </div>
                  )}
                </div>
                <div className="max-w-xl text-16px leading-20px md:text-20px md:leading-24px lg:max-w-2xl lg:text-16px lg:leading-20px">
                  {description}
                </div>

                {fiches && (
                  <div>
                    <hr className="mt-10 mb-16 border-medium-gray md:my-12" />
                    <div className="mb-8 md:mb-10 md:flex md:items-center md:justify-between lg:mb-12">
                      <div className="mb-4 text-24px leading-30px md:mb-0 md:text-28px md:leading-36px">Contenus</div>
                      <div className="text-16px leading-20px md:text-20px md:leading-24px">
                        Contient {fiches.length} fiches
                      </div>
                    </div>
                    <div className="grid gap-4 md:grid-cols-2">
                      {fiches.map((fiche, i) => (
                        <FichePreviewList
                          key={`fiche-${i}`}
                          fiche={fiche}
                          canEdit={canEdit()}
                          anthologyId={anthologieId}
                          deleteFromAntho={deleteFicheFromAntho}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </PDFExport>
            </div>
          </div>

          <AnthologieEditor show={editMode} id={anthologie._id} closeEditor={closeEditor} />
        </div>
      </>
    );
  } else {
    return 'Chargement...';
  }
}

export default withRouter(AnthologiePage);
