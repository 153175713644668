import React from 'react';
import { SearchPanel } from '../components';

function SearchPage() {
  return (
    <>
      <SearchPanel />
    </>
  );
}

export default SearchPage;
