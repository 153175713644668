import { useEffect } from 'react';
import withRouter from '../../../HOC/withRouter';

function ScrollToTop({ location }) {
  useEffect(() => {
    const page = new URLSearchParams(location.search).get('page');
    if (!page) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return null;
}

export default withRouter(ScrollToTop);
