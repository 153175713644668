import React, { Component } from 'react';
import { Logo, Link } from '../components';
import LogoFCI from '../styles/svg/fci-logo.svg';
import LogoUQAM from '../styles/svg/uqam-logo.svg';
import LogoNT2 from '../styles/svg/nt2-logo.png';
import LogoFigura from '../styles/svg/figura-logo.svg';
import { Helmet } from 'react-helmet';

class APropos extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>À propos - Encodage</title>
        </Helmet>
        <div className="mb-20 mt-16 2xl:mx-40">
          <h1 className="mb-16 md:pl-12 lg:pl-20 2xl:mb-8">
            À propos
            <br />
            d'Encodage
          </h1>
          <div className="ml-auto max-w-xl lg:max-w-4xl">
            <h4 className="mb-16 2xl:mb-8">
              Lancé en 2022 dans le cadre du projet Encodage: structurer, exploiter et dynamiser les recherches sur le
              contemporain à l’ère du web des données, financé par la Fondation canadienne pour l’innovation (FCI,
              2020-2023), Encodage est un outil de recherche et d’anthologisation sur l’imaginaire puisant dans
              l'écosystème numérique sur le contemporain du Centre Figura, développé par le Laboratoire NT2.
            </h4>
            <h5>
              Propulsé par l’API de recherche Algolia, le moteur de recherche multisite d’Encodage permet d'interroger
              les sites web de l’écosystème numérique et d’en fédérer les résultats en un seul et même endroit. L’outil
              Encodage permet également la création d’anthologies, c’est-à-dire de listes à partir de contenus issus de
              divers sites web de recherche, de les sauvegarder et de les partager.
            </h5>
          </div>
          <div className="my-20 max-w-6xl bg-lightest-gray py-8 px-6 md:p-12 lg:p-16 2xl:p-20">
            <div className="mb-6 text-20px leading-24px md:mb-12 md:text-24px md:leading-30px lg:text-36px lg:leading-48px">
              L’outil de recherche Encodage puise dans les sites web suivants :
            </div>
            <div className="flex flex-col gap-6 text-14px leading-20px md:text-18px md:leading-24px lg:text-21px lg:leading-24px">
              <Link text="Observatoire de l'imaginaire contemporain" url="https://oic.uqam.ca/" />
              <Link
                text="Laboratoire ALN|NT2"
                url="https://nt2.uqam.ca/"
              />
              <Link text="Captures, revue interdisciplinaire" url="https://revuecaptures.org/" />
              <Link text="Opuscules" url="https://opuscules.ca/" />
              <Link text="Archiver le présent" url="https://archiverlepresent.org/" />
              <Link text="Pop-en-stock" url="https://popenstock.ca" />
              <Link text="Percées" url="https://percees.uqam.ca/" />
              <Link text="Revue Postures" url="https://revuepostures.com/" />
              <Link text="Observatoire du discours financier en traduction" url="https://odft.nt2.ca/" />
              <Link text="Quartier F" url="https://quartierf.org/" />
            </div>
          </div>
          <div className="ml-auto max-w-xl lg:max-w-4xl">
            <h4 className="mb-16 2xl:mb-8">
              Encodage, qui se veut une expérience de littératie numérique sur les stratégies interprétatives de
              l’imaginaire contemporain, est proposé par Bertrand Gervais, titulaire de la chaire de recherche du Canada
              sur les arts et les littératures numériques, ainsi que par Nathalie Lacelle, titulaire de la Chaire de
              recherche en littératie médiatique multimodale, tous deux de l’UQAM.
            </h4>
            <h5>
              Le projet Encodage entend exploiter et valoriser des bases de données diffusant de la recherche en
              sciences sociales et humaines, arts et lettres sur l’imaginaire contemporain, étudié depuis les pratiques
              culturelles, artistiques et littéraires, en plus de favoriser le développement de pistes interprétatives
              de cet imaginaire, grâce à l’examen et à l’acquisition d’une littératie numérique, définie dans le champ
              de la littératie médiatique multimodale.
            </h5>
          </div>
          <div className="mt-24 flex flex-wrap gap-4 sm:justify-start md:mt-32 2xl:gap-12">
            <Logo svg={LogoFCI} name="Fondation canadienne pour l'innovation" url="https://www.innovation.ca/" />
            <Logo svg={LogoUQAM} name="Faculté des arts de l'UQAM" url="https://arts.uqam.ca/" />
            <Logo
              svg={LogoNT2}
              name="Laboratoire NT2"
              url="https://nt2.uqam.ca/"
            />
            <Logo svg={LogoFigura} name="Figura" url="https://figura.uqam.ca/" />
          </div>
        </div>
      </>
    );
  }
}

export default APropos;
