import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pager, AnthologiePreview } from '..';
import Grid from './Grid';

export default class AnthologiesList extends Component {
  render() {
    let { anthologies, withPager } = this.props;
    anthologies = anthologies.filter((antho) => antho.creator !== null);
    const number_per_pages = 12;
    if (anthologies) anthologies = anthologies.reverse();
    return (
      anthologies &&
      (withPager ? (
        <Pager number_per_pages={number_per_pages}>
          {anthologies.map((lib, i) => (
            <AnthologiePreview anthologie={lib} key={`antho-${i}`} />
          ))}
        </Pager>
      ) : (
        <Grid>
          {anthologies.map((lib, i) => (
            <AnthologiePreview anthologie={lib} key={`antho-${i}`} />
          ))}
        </Grid>
      ))
    );
  }
}
AnthologiesList.propTypes = { anthologies: PropTypes.array };
