import React from 'react';
import Header from './sections/Header';
import Footer from './sections/Footer';
import { ScrollToTop } from '..';
import { useAuthContext } from '../../common/context/authContext';

function DefaultLayout({ children }) {
  const { isLoading, isLoggedIn } = useAuthContext();

  return (
    <>
      <div className="flex h-fit min-h-screen flex-col justify-between">
        <ScrollToTop />
        <Header isLoggedIn={isLoggedIn} />
        <div className="mx-4 flex h-fit min-h-screen flex-1 flex-col md:mx-12">{children}</div>
        <Footer />
      </div>
      <div className={`absolute top-0 left-0 h-full w-full bg-white ${isLoading ? 'block' : 'hidden'}`}></div>
    </>
  );
}

export default DefaultLayout;
