import React, { useEffect, useState } from 'react';
import { getUserAnthologies, updateAnthologie } from '../../api';
import { AnthologieEditor } from '..';
import { useAuthContext } from '../../common/context/authContext';

function AnthologieSelector({ fiche_id, closeSelector, show }) {
  const { user } = useAuthContext();

  const [selected, setSelected] = useState([]);
  const [anthologies, setAnthologies] = useState([]);
  const [createAnthologie, setCreateAnthologie] = useState(false);

  useEffect(() => {
    if (user) {
      getAnthologies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getAnthologies = () => {
    getUserAnthologies(user._id).then((anthologies) => {
      let selected = [];
      anthologies.forEach((antho) => {
        if (antho.fiches && antho.fiches.find((fiche) => fiche._id === fiche_id || fiche === fiche_id)) {
          selected = [...selected, antho._id];
        }
      });
      setSelected(selected);
      setAnthologies(anthologies);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    anthologies.forEach((antho) => {
      if (selected.includes(antho._id)) {
        if (!antho.fiches) {
          antho.fiches = [fiche_id];
        } else {
          if (!antho.fiches.find((fiche) => fiche._id === fiche_id || fiche === fiche_id)) {
            antho.fiches = [...antho.fiches, fiche_id];
          }
        }
      } else {
        if (antho.fiches) {
          antho.fiches = antho.fiches.filter((val) => val !== fiche_id && val._id !== fiche_id);
        } else {
          antho.fiches = [];
        }
      }

      updateAnthologie(antho).then(() => closeSelector());
    });
  };

  const handleChange = (e) => {
    let filtedSelection;
    selected.includes(e.target.value)
      ? (filtedSelection = selected.filter((val) => val !== e.target.value))
      : (filtedSelection = [...selected, e.target.value]);

    setSelected(filtedSelection);
  };

  const addNewAnthologie = (e) => {
    e.preventDefault();
    setCreateAnthologie(true);
  };

  const closeEditor = () => {
    setCreateAnthologie(false);
    getAnthologies();
  };

  const selectCheckbox = (e) => {
    e.stopPropagation();
    const checkbox = e.currentTarget.querySelector('input[type=checkbox]');
    let filtedSelection;

    selected.includes(checkbox.value)
      ? (filtedSelection = selected.filter((val) => val !== checkbox.value))
      : (filtedSelection = [...selected, checkbox.value]);

    setSelected(filtedSelection);
  };

  return (
    <>
      <div
        className={`fixed inset-0 h-full w-full bg-black opacity-20 ${show && !createAnthologie ? 'block' : 'hidden'}`}
      ></div>
      <div
        className={`fixed top-0 right-0 z-50 h-full max-w-2xl overflow-y-auto bg-white px-6 pt-10 pb-4 ${
          show && !createAnthologie ? 'block' : 'hidden'
        }`}
      >
        <div className="mb-12">
          <div className="mb-8 flex items-center justify-between text-24px leading-30px md:text-28px md:leading-36px">
            <div>
              <div className="mr-8 md:mr-0">Sauvegarder la source</div>
            </div>
            <button onClick={closeSelector}>
              <svg width="1em" height="1em" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41z"
                ></path>
              </svg>
            </button>
          </div>
          <div className="mb-0 mt-16 text-16px leading-20px md:mt-0 md:text-20px md:leading-24px">
            Sélectionner l’anthologie où sauvegarder cette source.
          </div>

          <form onSubmit={handleSubmit}>
            <div className="flex flex-col overflow-auto py-8 text-16px leading-20px md:text-24px md:leading-30px">
              {anthologies.map((antho, i) => (
                <div
                  key={`antho-${i}`}
                  className={`mb-4 flex cursor-pointer items-center border p-6 ${
                    selected.includes(antho._id) ? 'border-bright-orange' : 'border-black'
                  }`}
                  onClick={selectCheckbox}
                >
                  <input
                    className="cursor-pointer text-bright-orange"
                    type="checkbox"
                    name={antho._id}
                    value={antho._id}
                    onChange={handleChange}
                    // onClick={handleChange}
                    checked={selected.includes(antho._id)}
                  />
                  {/* <div className="mb-4 text-24px leading-30px  md:text-28px md:leading-36px   xl:text-36px xl:leading-48px "> */}

                  <span className="pl-5 line-clamp-6 sm:line-clamp-5 lg:line-clamp-3 xl:line-clamp-4">
                    {antho.name}
                  </span>
                </div>
              ))}
              <button
                className="flex items-center border border-medium-gray p-6 text-custom-gray hover:border-black hover:text-black"
                onClick={addNewAnthologie}
              >
                <svg className="mr-3" width="1em" height="1em" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M19 12.998h-6v6h-2v-6H5v-2h6v-6h2v6h6z"></path>
                </svg>
                <span>Nouvelle anthologie</span>
              </button>
            </div>

            <button
              type="submit"
              className="w-full border border-black px-6 py-4 text-14px leading-20px hover:bg-light-gray md:w-auto md:text-18px md:leading-24px xl:text-21px"
            >
              Sauvegarder
            </button>
          </form>
        </div>
      </div>
      <AnthologieEditor show={createAnthologie} closeEditor={closeEditor} />
    </>
  );
}

export default AnthologieSelector;
