import React, { useEffect, useRef, useState } from 'react';
import { DefaultLayout } from './components';
import { Routes, Route } from 'react-router-dom';
import withRouter from './HOC/withRouter';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-core';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import {
  Home,
  Error404,
  AProposPage,
  CreditsPage,
  AnthologiesListPage,
  AccountPage,
  AnthologiePage,
  FichePage,
  ProfilPage,
  SearchPage,
} from './pages';
import qs from 'qs';
import './styles/index.css';
import { useAuthContext } from './common/context/authContext';
import ReactGA from 'react-ga4';
import { constants } from './common/constants/constant';
import ResetPasswordPage from './pages/ResetPassword.page';

//ANALYTICS - TODO react-ga incompatible with GA4, need to find alternative
ReactGA.initialize(constants.trackingId);
ReactGA.send('pageview');

//ALGOLIA
const DEBOUNCE_TIME = 400;
const searchClient = algoliasearch('VGW0S7WRXX', 'b3fb36e54f2ab24758b8bcdd840121f6');

function App({ location, navigate }) {
  //SEARCH URL
  const createURL = (state) => `?${qs.stringify(state)}`;

  const { user, isLoggedIn } = useAuthContext();

  const searchStateToUrl = (searchState) => (searchState ? createURL(searchState) : '');
  const urlToSearchState = ({ search }) => qs.parse(search.slice(1));

  const debouncedSetStateRef = useRef(null);
  const [searchState, setSearchState] = useState(urlToSearchState(location));

  const onSearchStateChange = (updatedSearchState) => {
    clearTimeout(debouncedSetStateRef.current);

    if (location.pathname === '/outils-recherche' || location.pathname === '/') {
      debouncedSetStateRef.current = setTimeout(() => {
        navigate(searchStateToUrl(updatedSearchState));
      }, DEBOUNCE_TIME);

      setSearchState(updatedSearchState);
    }
  };

  useEffect(() => {
    if (location.search) {
      setSearchState(urlToSearchState(location));
    }
  }, [location]);

  useEffect(() => {
    if (location.search) {
      setSearchState(urlToSearchState(location));
    }
  }, [location]);

  const other_routes = [
    {
      path: '/anthologie/:anthologieId',
      main: AnthologiePage,
    },
    {
      path: '/anthologie/:anthologyId/:ficheNid',
      main: FichePage,
    },
    {
      path: '/fiche/:ficheNid',
      main: FichePage,
    },
    {
      path: '/profil/:profilId',
      main: ProfilPage,
    },
    {
      path: '/outils-recherche',
      main: SearchPage,
    },
    {
      path: '/reset-password',
      main: ResetPasswordPage,
    },

    {
      path: '*',
      main: Error404,
    },
  ];
  const menu_routes = [
    {
      name: 'Home',
      path: '/',
      exact: true,
      main: Home,
    },
    {
      name: 'User',
      path: '/account',
      main: AccountPage,
    },
    {
      name: 'Anthologies',
      path: '/anthologies',
      main: AnthologiesListPage,
    },
    {
      name: 'À propos',
      path: '/a-propos',
      main: AProposPage,
    },
    {
      name: 'Crédits',
      path: '/credits',
      main: CreditsPage,
    },
  ];

  const routes = [...menu_routes, ...other_routes];

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        closeOnClick
        newestOnTop
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <InstantSearch
        indexName="source"
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <DefaultLayout>
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={<route.main isLoggedIn={isLoggedIn} user={user} callback={route.callback} path={route.path} />}
              />
            ))}
          </Routes>
        </DefaultLayout>
      </InstantSearch>
    </>
  );
}

export default withRouter(App);
