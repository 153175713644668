import React, { useState } from 'react';
import { SortBy, Stats } from 'react-instantsearch-dom';
import Helmet from 'react-helmet';

import { RefinementList, CurrentRefinements, Pagination } from 'react-instantsearch-dom';
import withRouter from '../../HOC/withRouter';

import { AutocompleteSearchbar, ReinitialiserRefinements, SearchResults, FilterTab } from '..';
import { useAuthContext } from '../../common/context/authContext';

function SearchPanel({ location }) {
  const query = new URLSearchParams(location.search);
  const { isLoggedIn } = useAuthContext();
  const [showFilters, setShowFilters] = useState(false);
  const [searchedWord, setSearchedWord] = useState(query.get('query'));

  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <>
      <Helmet>
        <title>Résultats - Encodage</title>
      </Helmet>
      <div className="mt-6 mb-20 lg:mt-16">
        <div className="flex flex-col">
          <div className="order-1 mb-8 md:order-2 xl:order-1">
            <AutocompleteSearchbar setSearchedWord={setSearchedWord} searchedWord={searchedWord} isResultsPage />
          </div>

          <div className="order-2 mb-8 text-24px leading-30px md:order-1 md:text-28px md:leading-36px lg:text-36px lg:leading-48px xl:order-2 xl:mb-16">
            <Stats
              translations={{
                stats(nbHits, processingTimeMS, nbSortedHits, areHitsSorted) {
                  if (searchedWord && searchedWord.length > 0)
                    return `${nbHits.toLocaleString()} résultats trouvés pour "${searchedWord}".`;
                  else return `${nbHits.toLocaleString()} résultats trouvés.`;
                },
              }}
            />
          </div>
        </div>

        <div className="mb-10  flex items-center gap-6 sm:justify-between lg:justify-start">
          <SortBy
            defaultRefinement="source"
            items={[
              {
                value: 'source',
                label: 'Plus Pertinents',
              },
              {
                value: 'source_date_publication_desc',
                label: 'Plus Récents',
              },
            ]}
          />
          <button
            className="flex  items-center justify-center border border-black p-4 text-14px leading-20px hover:bg-light-gray md:text-21px md:leading-24px xl:hidden"
            onClick={toggleShowFilters}
          >
            <div>
              <svg
                width="1em"
                height="1em"
                className="mr-2"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 11.3333V12.6667H6V11.3333H2ZM2 3.33333V4.66667H8.66667V3.33333H2ZM8.66667 14V12.6667H14V11.3333H8.66667V10H7.33333V14H8.66667ZM4.66667 6V7.33333H2V8.66667H4.66667V10H6V6H4.66667ZM14 8.66667V7.33333H7.33333V8.66667H14ZM10 6H11.3333V4.66667H14V3.33333H11.3333V2H10V6Z"
                  fill="black"
                />
              </svg>
            </div>
            <div>Filtres</div>
          </button>
        </div>

        <div
          className={
            showFilters ? 'fixed top-0 right-0 z-50 h-full w-full overflow-y-auto bg-white' : 'static block flex h-auto'
          }
        >
          <div className={showFilters ? 'flex items-center justify-between p-6 text-28px leading-36px' : 'hidden'}>
            <div>Filtres</div>

            <svg className="cursor-pointer" width="1em" height="1em" viewBox="0 0 24 24" onClick={toggleShowFilters}>
              <path
                fill="currentColor"
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41z"
              ></path>
            </svg>
          </div>

          <div className={`my-12 hidden list-none ${showFilters ? 'show-filters' : 'xl:block xl:w-1/3'}`}>
            <div className={showFilters ? 'w-auto' : 'xl:w-2/3'}>
              <CurrentRefinements />
              <FilterTab title="Source">
                <RefinementList
                  attribute="source"
                  facetOrdering
                  limit={1000}
                  translations={{
                    showMore(expanded) {
                      return expanded ? 'Afficher plus' : 'Afficher moins';
                    },
                    noResults: 'Aucun résultats',
                    submitTitle: 'Soumettez votre requête de recherche.',
                    resetTitle: 'Effacer votre requête de recherche.',
                    placeholder: 'Rechercher...',
                  }}
                />
              </FilterTab>
              <FilterTab title="Types">
                <RefinementList
                  attribute="type"
                  facetOrdering
                  limit={1000}
                  translations={{
                    showMore(expanded) {
                      return expanded ? 'Afficher plus' : 'Afficher moins';
                    },
                    noResults: 'Aucun résultats',
                    submitTitle: 'Soumettez votre requête de recherche.',
                    resetTitle: 'Effacer votre requête de recherche.',
                    placeholder: 'Rechercher...',
                  }}
                />
              </FilterTab>
              <FilterTab title="Auteur.es">
                <RefinementList
                  attribute="authors.fullname"
                  facetOrdering
                  limit={20}
                  showMore
                  showMoreLimit={1000}
                  translations={{
                    showMore(expanded) {
                      return expanded ? 'Afficher moins' : 'Afficher plus';
                    },
                    noResults: 'Aucun résultats',
                    submitTitle: 'Soumettez votre requête de recherche.',
                    resetTitle: 'Effacer votre requête de recherche.',
                    placeholder: 'Rechercher...',
                  }}
                />
              </FilterTab>
              <div className="max-w-md px-6 xl:px-0">
                <button
                  className={`link-black mt-4 w-full border border-black bg-black px-6 py-4 text-14px leading-20px text-white lg:text-21px lg:leading-24px ${
                    showFilters ? 'block' : 'hidden'
                  }`}
                  onClick={toggleShowFilters}
                >
                  Appliquer
                </button>
              </div>
              <ReinitialiserRefinements className="mx-auto mt-8 max-w-md px-6 text-18px leading-24px xl:mt-12 xl:max-w-none xl:px-0" />
            </div>
          </div>

          <div className={`xl:w-2/3 ${showFilters ? 'hidden' : 'block'}`}>
            <SearchResults isLoggedIn={isLoggedIn} />
            <Pagination defaultRefinement={4} />
          </div>
        </div>
      </div>
    </>
  );
}
export default withRouter(SearchPanel);
