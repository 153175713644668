import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="flex items-center justify-between bg-black p-4 px-6 text-white">
      <div className="text-21px font-bold leading-26-25px">
        <Link to="/a-propos">Encodage</Link>
      </div>
      <Link
        to="/credits"
        className="border-b border-white text-14px leading-20px md:flex md:gap-4 md:text-18px md:leading-24px"
      >
        Crédits
      </Link>
    </div>
  );
}

export default Footer;
