import React from 'react';
import { Link } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import withRouter from '../../../HOC/withRouter';
import { useAuthContext } from '../../../common/context/authContext';

function MobileMenu() {
  const { isLoggedIn } = useAuthContext();

  return (
    <Popover>
      <Popover.Button className="relative z-50">
        {({ open }) => (
          <>
            <svg className={`${open ? 'hidden' : ''}`} width="2rem" height="2rem" viewBox="0 0 24 24">
              <path fill="currentColor" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
            </svg>
            <svg className={`${open ? '' : 'hidden'}`} width="2rem" height="2rem" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41z"
              ></path>
            </svg>
          </>
        )}
      </Popover.Button>
      <Popover.Overlay className="fixed inset-0 bg-white" />

      <Popover.Panel className="fixed inset-0 z-10 px-12 pt-32">
        {({ close }) => (
          <div className="flex flex-col gap-8 text-21px leading-24px">
            <div>
              <Link
                to="/"
                onClick={async () => {
                  close();
                }}
              >
                Recherche
              </Link>
            </div>
            {/* )} */}

            <Link
              to="/anthologies"
              onClick={async () => {
                close();
              }}
            >
              Anthologies
            </Link>
            {isLoggedIn ? (
              <Link
                to="/account"
                onClick={async () => {
                  close();
                }}
              >
                Compte
              </Link>
            ) : (
              <Link to="/account">Connexion</Link>
            )}
            <Link
              to="/a-propos"
              onClick={async () => {
                close();
              }}
            >
              À propos
            </Link>
            <Link
              to="/credits"
              onClick={async () => {
                close();
              }}
            >
              Crédits
            </Link>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
}

export default withRouter(MobileMenu);
