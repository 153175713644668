import React, { useState } from 'react';

function InputPassword({ name, value, title, onChange }) {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOnChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="text-16px leading-24px md:text-20px">
      <div className="mb-2 font-semibold">
        {title} <span className="text-dark-orange">*</span>
      </div>
      <div className="flex items-center justify-between border-b border-black">
        <input
          className="border-none pl-0 text-20px leading-24px outline-none focus:border-none focus:ring-transparent"
          type={showPassword ? 'text' : 'password'}
          name={name}
          value={value}
          onChange={handleOnChange}
          required
        />
        <svg
          className="cursor-pointer opacity-50 hover:opacity-100"
          width="1.25em"
          height="1.25em"
          viewBox="0 0 24 24"
          onClick={toggleShowPassword}
        >
          <path
            fill="currentColor"
            d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3z"
          ></path>
        </svg>
      </div>
    </div>
  );
}

export default InputPassword;
