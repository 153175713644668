import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faBookmark } from '@fortawesome/free-solid-svg-icons';

export default class AjouterAnthologie extends Component {
  render() {
    return (
      <button className={`btn-add-anthologie ${this.props.type}`} onClick={this.props.addToAnthologie}>
        {this.props.type === 'anthologie' ? <FontAwesomeIcon icon={faBookmark} /> : <FontAwesomeIcon icon={faStar} />}
      </button>
    );
  }
}
