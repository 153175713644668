import { loginUser, registerUser, testUserToken } from './api';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useAuth = () => {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();

  const signIn = async (data) => {
    try {
      let result = await loginUser(data);
      if (result.accessToken) {
        setIsLoading(true);
        const { accessToken: token } = result;
        localStorage.setItem('accessToken', token);
        setAccessToken(token);
      }
      return result;
    } catch (err) {
      console.error(err);
    }
  };

  const signUp = async (data) => {
    try {
      let result = await registerUser(data);
      if (result.accessToken) {
        setIsLoading(true);
        const { accessToken: token } = result;
        localStorage.setItem('accessToken', token);
        setAccessToken(token);
      }
      return result;
    } catch (err) {
      console.error(err);
    }
  };

  const isAdmin = () => {
    if (user && user.roles) {
      return !!user.roles.filter((el) => el.name === 'admin' || el.name === 'moderator').length;
    }
    return false;
  };

  const signOut = useCallback(() => {
    setUser(null);
    setAccessToken(null);
    setIsLoggedIn(false);
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (isLoggedIn && !localStorage.getItem('accessToken')) {
      signOut();
    }
  }, [location, signOut, isLoggedIn]);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token && !isLoggedIn) {
      testUserToken(token)
        .then((res) => {
          if (res && res.tokenIsValid) {
            setIsLoggedIn(true);
            setIsLoading(false);
            setUser(res.user);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          localStorage.clear();
          if (e.response?.data.message) toast.error(e.response?.data.message);
        });
    } else {
      setIsLoading(false);
    }
  }, [accessToken, location, isLoggedIn]);

  return { user, accessToken, isLoading, isLoggedIn, signIn, signUp, signOut, isAdmin };
};
